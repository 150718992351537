import React, {createContext} from 'react';
import {getApp, getApps, initializeApp,} from "firebase/app";
import {child, endAt, equalTo, getDatabase, limitToLast, orderByChild, push, query, ref, set, startAt, remove, off, update} from "firebase/database";
import {browserLocalPersistence, browserPopupRedirectResolver, getAuth, GoogleAuthProvider, initializeAuth, linkWithPhoneNumber, OAuthProvider, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, unlink, updatePhoneNumber, signInWithEmailAndPassword, updatePassword} from "firebase/auth";
import {getStorage, ref as stRef} from "firebase/storage";
import {getReactNativePersistence} from './react-native-persistance';

const FirebaseContext = createContext(null);

let firebase = {
    app: null,
    database: null,
    auth: null,
    storage: null,
    refApi: null
}

let refApi = null;

const createApiStructure = (db) => {
    return {
        setUserRequiredReloadApi: (uid, status) => set(ref(db, "users/" + uid + "/required_reload"), status),
        setUserContactsApi: (uid, contacts) => set(ref(db, "users/" + uid + "/contacts"), contacts),
        saveUserLocation: (uid, location) => set(ref(db, 'users/' + uid + '/location'), location),
        saveLocationByUid: (uid, location) => set(ref(db, 'locations/' + uid), location),
        saveCurrentDateTime: (uid, date) => set(ref(db, 'users/' + uid + '/current_time'), date),
        saveRestedStatus: (uid, status) => set(ref(db, 'users/' + uid + '/is_rested'), status),
        saveRestedShow: (uid, status) => set(ref(db, 'users/' + uid + '/is_rested_show'), status),
        setDriverFavouriteStatusApi: (uid, driverId, status) => set(ref(db, "users/" + uid + "/driver_favourite/" + driverId + '/status'), status),
        removeDriverFavouriteApi: (uid, driverId) => remove(ref(db, "users/" + uid + "/driver_favourite/" + driverId)),
        bookingRequiredPriceApi: (bookingKey, action) => set(ref(db, "bookings/" + bookingKey + "/requiredPrice"), action),
        pushPriceStepApi: (bookingKey, object) => push(ref(db, "bookings/" + bookingKey + "/priceStep"), object),
        setEstimateBooking: (bookingKey, price) => set(ref(db, "bookings/" + bookingKey + "/estimate"), price),
        setIsMetterApi: (bookingKey, isMetter) => set(ref(db, "bookings/" + bookingKey + "/isMetter"), isMetter),
        setCountTimeBookingApi: (bookingKey, countTime) => set(ref(db, "bookings/" + bookingKey + "/count_time"), countTime),
        setStatusBookingApi: (bookingKey, status) => set(ref(db, "bookings/" + bookingKey + "/status"), status),
        setDriverBookingApi: (bookingKey, driver) => set(ref(db, "bookings/" + bookingKey + "/driver"), driver),
        setDriverReferralRef: (uid, phone) => set(ref(db, "users/" + uid + "/signupViaReferral"), phone),
        pushReportDriverApi: (data) => push(ref(db, "report_driver"), data),
        setCodeDriverApi: (codes) => set(ref(db, "drivers_open/codes"), codes),
        setCodeDriverUserApi: (uid, code) => set(ref(db, "users/" + uid + "/codeDriver"), code),
        setCartypeUserApi: (uid, carType) => set(ref(db, "users/" + uid + "/carType"), carType),
        setCartypeCodeUserApi: (uid, carTypeCode) => set(ref(db, "users/" + uid + "/carTypeCode"), carTypeCode),
        setWaitingTimeBooking: (bookingKey, time) => set(ref(db, "bookings/" + bookingKey + "/waitingTime"), time),
        setAcceptedWaringBooking: (bookingKey, value) => set(ref(db, "bookings/" + bookingKey + "/waring_accept"), value),
        setBookingPrices: (bookingKey, prices) => set(ref(db, "bookings/" + bookingKey + "/prices"), prices),
        setBookingReport: (bookingKey, report) => set(ref(db, "bookings/" + bookingKey + "/report"), report),
        bookingSetPriceApi: (bookingKey, price) => set(ref(db, "bookings/" + bookingKey + "/estimateCurrent"), price),
        setRiderFavouriteApi: (uid, phone) => set(ref(db, "users/" + uid + "/river_favourite/" + phone), true),
        setContactLimitedApi: (uid, phone, obj) => set(ref(db, "users/" + uid + "/contact_limited/" + phone), obj),
        removeContactLimitedApi: (uid, phone) => remove(ref(db, "users/" + uid + "/contact_limited/" + phone)),
        removeSignupViaReferralApi: (uid) => remove(ref(db, "users/" + uid + "/signupViaReferral")),
        removeRiderFavouriteApi: (uid, phone) => remove(ref(db, "users/" + uid + "/river_favourite/" + phone)),
        bookingDataCarApi: (bookingKey, dataCar) => set(ref(db, "bookings/" + bookingKey + "/dataCar"), dataCar),
        setBookingDropAddressApi: (bookingKey, address) => set(ref(db, "bookings/" + bookingKey + "/dropAddress"), address),
        setEsimateDriverAccepted: (bookingKey, driverAcceptedId, estimate) => set(ref(db, "bookings/" + bookingKey + "/driver_accepted/" + driverAcceptedId + '/estimate'), estimate),
        removeDriverAcceptedApi: (bookingKey, driverAcceptedId) => remove(ref(db, "bookings/" + bookingKey + "/driver_accepted/" + driverAcceptedId)),
        markBookingLaterAcceptedApi: (bookingKey, status) => set(ref(db, "bookings/" + bookingKey + "/has_accepted"), status),
        setBookingDropApi: (bookingKey, drop) => set(ref(db, "bookings/" + bookingKey + "/drop"), drop),
        saveGroupInfo: (uid, data) => set(ref(db, 'users/' + uid + '/group_info'), data),
        setCurrentAreaId: (uid, areaId) => set(ref(db, 'users/' + uid + '/currentAreaId'), areaId),
        setGroupNotifyApi: (id, status) => set(ref(db, "group_driver/" + id + "/is_notify"), status),
        setGroupCodesApi: (id, codes) => set(ref(db, "group_driver/" + id + "/codes"), codes),
        updateProfileRef: (id) => ref(db, "users/" + id),
        updateProfileDoRef: (id, data) => update(ref(db, "users/" + id), data),

        updateProfileEmailRef: (email, password, newEmail) => signInWithEmailAndPassword(email, password)
            .then(function (userCredential) {
                return userCredential.user.updateEmail(newEmail)
            }),
        updateProfilePasswordRef: (email, password, newPassword) => signInWithEmailAndPassword(email, password)
            .then(function (userCredential) {
                return userCredential.user.updatePassword(newPassword)
            }).catch(error => {
                console.log("error change passs", error);
            }),

        updatePasswordRef: async (newPassword) => await updatePassword(getAuth().currentUser, newPassword),

        updateQueueDriver: (id, status) => set(ref(db, "users/" + id + "/queue"), status),
        setMemberAreaApi: (areaId, uid, status) => set(ref(db, "driver_group/" + areaId + "/members/" + uid + "/status"), status),
        setVoteResultApi: (voteId, uid, result) => set(ref(db, "votes/" + voteId + "/result/" + uid + "/status"), result),
        setVoteDateResultApi: (voteId, uid, result) => set(ref(db, "votes/" + voteId + "/result/" + uid + "/date_vote"), result),
        saveGroupDriver: (uid, group) => set(ref(db, 'users/' + uid + '/groupDriver'), group),
        saveTracking: (bookingId, location) => push(ref(db, 'tracking/' + bookingId), location),
        chatMessageReaderApi: (bookingId, chatSessionId, chatUId, uid) => set(ref(db, 'chats/' + bookingId + '/messages/' + chatSessionId + '/' + chatUId + '/readers/' + uid), true),
        mistakeActionRef: ref(db, "mistakes/"),
        trackingApiRef: (bookingId) => ref(db, 'tracking/' + bookingId),
        updateBookingViewed: (bookingId) => set(ref(db, 'bookings/' + bookingId + '/isView'), true),
        setMemberFavouriteApi: (areaId, uid, status) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/is_favourite'), status),
        setMemberRenewalApi: (areaId, uid, status) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/enable_renewal'), status),
        setMemberPauseApi: (areaId, uid, status) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/is_pause'), status),
        setMemberStatusApi: (areaId, uid, status) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/is_pause'), status),
        setMemberStatusJoinApi: (areaId, uid, status) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/status'), status),
        setMemberDateAddApi: (areaId, uid, date) => set(ref(db, 'areas/' + areaId + '/members/' + uid + '/date_add'), date),
        removeMemberAreaApi: (areaId, uid) => remove(ref(db, 'areas/' + areaId + '/members/' + uid)),
        addMemberAreaApi: (areaId, uid, data) => set(ref(db, 'areas/' + areaId + '/members/' + uid), data),
        setOkPricesFromPricesAreaApi: (areaId, prices) => set(ref(db, 'areas/' + areaId + '/ok_prices'), prices),
        setPricesFromOkPricesAreaApi: (areaId, prices) => set(ref(db, 'areas/' + areaId), prices),
        setPricesTimeStoneFromOkPricesTimeStomeAreaApi: (areaId, prices) => set(ref(db, 'areas/' + areaId + '/pricestimestone'), prices),
        setVotingAreaApi: (areaId, type, value) => set(ref(db, 'areas/' + areaId + '/' + type), value),
        pushAreaTermApi: (areaId, value) => push(ref(db, 'areas/' + areaId + '/terms'), value),
        setAreaTermByIdApi: (areaId, termId, value) => set(ref(db, 'areas/' + areaId + '/terms/' + termId), value),
        removeAreaTermByIdApi: (areaId, termId) => remove(ref(db, 'areas/' + areaId + '/terms/' + termId)),
        removeAreaByIdApi: (areaId) => remove(ref(db, 'areas/' + areaId)),
        setNotifyImportanceStatusApi: (notifyId, status) => set(ref(db, "notify_importance/" + notifyId + "/status"), status),
        setNotifyImportanceColorApi: (notifyId, status) => set(ref(db, "notify_importance/" + notifyId + "/color"), status),
        setNotifyImportanceRefIdApi: (notifyId, status) => set(ref(db, "notify_importance/" + notifyId + "/notify_ref_id"), status),
        stopFetchTradeApi: (key) => off(ref(db, 'trades/' + key)),
        setTotalTransactionApi: (key, count) => set(ref(db, 'trades/' + key + '/total_transaction'), count),
        setStatusWalletTransactionsApi: (idWallet, idTransaction, status) => set(ref(db, 'wallets/' + idWallet + '/transactions' + '/' + idTransaction + '/status'), status),
        setBalanceWalletApi: (idWallet, balance) => set(ref(db, 'wallets/' + idWallet + '/balance'), balance),
        setPrice1FromApi: (uid, priceId, price) => set(ref(db, "prices/" + uid + "/price1/" + priceId + '/from'), price),
        setPrice1AreaFromApi: (uid_cartypecode_prices, price1ItemId, price) => set(ref(db, "areas/" + uid_cartypecode_prices + "/price1/" + price1ItemId + '/from'), price),
        updatePrice1Api: (id, dataObj) => update(ref(db, "prices/" + id + "/price1"), dataObj),
        setPrice1GroupApi: (id, carTypeCode, pricesData) => set(ref(db, "group_driver/" + id + "/prices/" + carTypeCode + "/price1"), pricesData),
        setPrice2GroupApi: (id, carTypeCode, pricesData) => set(ref(db, "group_driver/" + id + "/prices/" + carTypeCode + "/price2"), pricesData),
        setPriceOpenGroupApi: (id, carTypeCode, price) => set(ref(db, "group_driver/" + id + "/prices/" + carTypeCode + "/open"), price),
        setPriceAddGroupApi: (id, carTypeCode, price) => set(ref(db, "group_driver/" + id + "/prices/" + carTypeCode + "/add"), price),
        setDriverCurrentAddressBookingApi: (bookingId, data) => set(ref(db, 'bookings/' + bookingId + '/driverCurrentAddress'), data),
        setNoteMapAddressBookingApi: (key, data) => set(ref(db, 'note_map_address/' + key), data),
        setUserLoginHistoryApi: (uid, timeStamp, value) => set(ref(db, `users/${uid}/loginHistory/${timeStamp}`), value),
        setPriceAreaFromGroupApi: (id, pricesData) => set(ref(db, "areas/" + id + "/prices"), pricesData),
        setCaDemConfigApi: (data) => set(ref(db, "ca_dem_config"), data),
        setNoteCallCenterApi: (id, note) => set(ref(db, "pa_call_center/" + id + "/note"), note),
        updateCallCenterApi: (id, pickup) => update(ref(db, "pa_call_center/" + id), pickup),
        setSupportNoteCallCenterApi: (id, note) => set(ref(db, "pa_call_center/" + id + "/support_note"), note),
        setSupportNoteCheckedCallCenterApi: (id, checked) => set(ref(db, "pa_call_center/" + id + "/support_note_checked"), checked),
        setAudioCallCenterApi: (id, audios) => set(ref(db, "pa_call_center/" + id + "/audios"), audios),
        setTimeCallCenterApi: (id, times) => set(ref(db, "pa_call_center/" + id + "/time_call_out"), times),
        setUserVehicleNumberLeader: (uid, vehicleNumberLeader) => set(ref(db, 'users/' + uid + '/vehicleNumberLeader'), vehicleNumberLeader),
        setUserNotifyTotalApi: (uid, value) => set(ref(db, `users/${uid}/notifyTotal`), value),
        setDriverVipStatusApi: (uid, status) => set(ref(db, "users/" + uid), status),
        setPricesOpenRef: (uid, value) => set(ref(db, "prices/" + uid + "/open"), value),
        setPrice1ItemRef: (uid, price1ItemId, value) => set(ref(db, "prices/" + uid + "/price1/" + price1ItemId), value),
        setPrice2ItemRef: (uid, price1ItemId, value) => set(ref(db, "prices/" + uid + "/price2/" + price1ItemId), value),
        setPrice1Api: (uid, value) => set(ref(db, "prices/" + uid + "/price1"), value),
        notifyImportanceIdApi: (id, data) => update(ref(db, "notify_importance/" + id), data),
        setPrice1Ref: (uid, prices) => set(ref(db, "prices/" + uid + "/price1"), prices),
        setPrice2Ref: (uid, prices) => set(ref(db, "prices/" + uid + "/price2"), prices),
        setPricesRef: (uid, value) => set(ref(db, "prices/" + uid), value),
        setPricesAddRef: (uid, value) => set(ref(db, "prices/" + uid + "/add"), value),
        setPricesLimitTimeRef: (uid, value) => set(ref(db, "prices/" + uid + "/limit_time_two_way"), value),
        approveDriverRef: (uid, status) => set(ref(db, "users/" + uid + "/approvedDriver"), status),
        approveDriverReasonRef: (uid, reason) => push(ref(db, "users/" + uid + "/approvedDriverReason"), reason),
        setTotalTrackingApi: (bookingId, total) => set(ref(db, 'bookings/' + bookingId + '/totalTracking'), total),
        setAcceptTimeStoneAddApi: (bookingId, add) => set(ref(db, 'bookings/' + bookingId + '/accept_time_stone/add'), add),
        setTimeLineApi: (bookingId, timeLine) => set(ref(db, 'bookings/' + bookingId + '/timeLine'), timeLine),
        updateBookingApi: (bookingId, object) => update(ref(db, 'bookings/' + bookingId), object),
        pushPaCallCenterApi: (dataPa) => push(ref(db, "pa_call_center"), dataPa),
        setTriggerActionStatusApi: (uid, id, status) => set(ref(db, "trigger_action/" + uid + "/" + id + "/status"), status),
        setTrackedBooking: (bookingId, value) => set(ref(db, "bookings/" + bookingId + "/" + "/tracked"), value),
        pushTriggerActionByUidApi: (uid, obj) => push(ref(db, "trigger_action/" + uid), obj),
        setCallCenterPaidPendingApi: (id, status) => set(ref(db, "pa_call_center/" + id + "/paid_pending"), status),
        setDriverActiveStatusApi: (uid, status) => set(ref(db, "users/" + uid + "/checkActive/status"), status),
        pushBookingSurchargeApi: (bookingKey, surcharge) => push(ref(db, "bookings/" + bookingKey + "/surcharges"), surcharge),
        delBookingSurchargeApi: (bookingKey, surchargeId) => remove(ref(db, "bookings/" + bookingKey + "/surcharges/" + surchargeId)),
        setUserLostConnectStatus: (uid, status) => set(ref(db, "users/" + uid + "/statusLostConnect"), status),
        cancelCallCenterApi: (id) => update(ref(db, "pa_call_center/" + id), {status: 'cancel', reason: 'HỆ THỐNG TỰ HUỶ'}),
        changeStateCallApi: (id, status) => update(ref(db, "pa_call_center/" + id), {status: status}),
        pushBookingNotiApi: (data) => push(ref(db, "booking_noti"), data),
        updateBookingNotiApi: (id, data) => update(ref(db, "booking_noti/" + id), data),
        removeRequestedApi: (bookingId) => remove(ref(db, 'bookings/' + bookingId + '/requestedDrivers'))
    }
}

const createFullStructure = (app, db, auth, storage, config) => {
    return {
        app: app,
        database: db,
        config: config,
        auth: auth,
        storage: storage,
        authRef: getAuth,
        unlink: unlink,
        googleProvider: new GoogleAuthProvider(),
        appleProvider: new OAuthProvider('apple.com'),
        phoneProvider: new PhoneAuthProvider(auth),
        RecaptchaVerifier: RecaptchaVerifier,
        signInWithPhoneNumber: signInWithPhoneNumber,
        updatePhoneNumber: updatePhoneNumber,
        googleCredential: (idToken, accessToken) => GoogleAuthProvider.credential(idToken, accessToken),
        linkWithPhoneNumber: linkWithPhoneNumber,
        mobileAuthCredential: (verificationId, code) => PhoneAuthProvider.credential(verificationId, code),
        usersRef: ref(db, "users"),
        bookingRef: ref(db, "bookings"),
        trackBookingRef: (id) => ref(db, "bookings/" + id),
        cancelreasonRef: ref(db, "cancel_reason"),
        settingsRef: ref(db, "settings"),
        settingsCountViewRef: ref(db, "settings/count_views"),
        paCallCenterRef: ref(db, "pa_call_center"),
        paCallCenterFilterRef: (startDate, endDate) => query(ref(db, 'driver_group'), ...[orderByChild('time'), startAt(startDate), endAt(endDate)]),
        paCallCenterDetailRef: (phone) => ref(db, "pa_call_center_detail/" + phone),
        paCallCenterDetailAllRef: () => ref(db, "pa_call_center_detail/"),
        binhanhTrackingRef: ref(db, "binhanh_tracking_api/"),
        paCallByIdRef: (id) => ref(db, "pa_call_center/" + id),
        binhanhTrackingByIdRef: (id) => ref(db, "binhanh_tracking_api/" + id),
        binhanhTrackingByIdBieSoXeRef: (id, biensoxe) => ref(db, "binhanh_tracking_api/" + id + "/data/" + biensoxe),
        notifyImportanceToRef: (uid) => query(ref(db, "notify_importance"), orderByChild("to"), equalTo(uid)),

        userByCodeRef: (code) => query(ref(db, "users/"), orderByChild("codeDriver"), equalTo(code)),
        notifyImportanceRef: () => ref(db, "notify_importance"),
        notifyImportanceIdRef: (id) => ref(db, "notify_importance/" + id),

        tasksRunnerRef: () => ref(db, "tasks"),
        votesByIdRef: (uid) => query(ref(db, "votes"), orderByChild(uid), equalTo(true)),
        votesRef: () => ref(db, "votes"),
        carTypesRef: ref(db, "rates/car_type"),
        carTypesPriceRef: (id) => ref(db, "rates/car_type/" + id + "/price2"),
        carTypesEditRef: (index) => ref(db, "rates/car_type/" + index),
        caTrucDemsRef: ref(db, "ca_truc_dem"),
        caTrucDemsConfigRef: ref(db, "ca_dem_config"),
        caTrucDemRef: (date) => ref(db, "ca_truc_dem/" + date),
        groupdriverRef: ref(db, "group_driver/"),
        setGroupdriverByIdRef: (id) => ref(db, "group_driver/" + id),
        reportdriverRef: ref(db, "report_driver"),
        promoRef: ref(db, "offers"),
        promoEditRef: (id) => ref(db, "offers/" + id),
        groupdriverTotalFirebaseRef: (id) => ref(db, "group_driver/" + id + "/totalDriver"),
        banneduidsRef: ref(db, "banned_uids"),
        banneduidsEditRef: (id) => ref(db, "banned_uids/" + id),
        sloganRef: () => ref(db, "slogan"),
        promoLocationRef: (id) => ref(db, "offers/" + id + "/promo_location"),
        groupCoordRef: (id) => ref(db, "group_driver/" + id + "/group_coords"),
        notifyRef: ref(db, "notifications/"),
        mistakeRef: ref(db, "mistakes/"),
        mistakeEditRef: (id) => ref(db, "mistakes/" + id),
        mistakeHistoryRef: (id) => ref(db, "mistakes/" + id + "/histories"),
        catrucdemHistoryRef: (id) => ref(db, "ca_truc_dem/" + id + "/histories"),
        catrucdemHistoryUpdateRef: (date_key, historyKey) => ref(db, "ca_truc_dem/" + date_key + "/histories/" + historyKey),
        mistakeConfigRef: ref(db, "mistake_config/"),
        taxiTeamsRef: ref(db, "taxi_teams/"),
        taxiTeamByIdRef: (id) => ref(db, "taxi_teams/" + id),
        notifyEditRef: (id) => ref(db, "notifications/" + id),
        mistakeConfigEditRef: (id) => ref(db, "mistake_config/" + id),
        taxiTeamsEditRef: (id) => ref(db, "taxi_teams/" + id),
        taxiTeamsEditImageRef: (id) => stRef(storage, `taxi_teams/${id}/image`),
        inviteMembersRef: (id) => ref(db, "taxi_teams/" + id + "/members"),
        inviteMemberDetailRef: (id, index) => ref(db, "taxi_teams/" + id + "/members/" + index),
        singleUserRef: (uid) => ref(db, "users/" + uid),
        profileImageRef: (uid) => stRef(storage, `users/${uid}/profileImage`),
        userLoginHistoryRef: (uid) => ref(db, `users/${uid}/loginHistory`),
        chatMessageStoreRef: (bookingId, chatSessionId, chatUId) => stRef(storage, 'chats/' + bookingId + '/messages/' + chatSessionId + '/' + chatUId + '/message'),
        coordImageRef: (timestamp, groupId) => stRef(storage, `group_driver/${groupId}/group_coord_${timestamp}/`),
        cmndRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/cmnd`),
        avatarRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/avatar`),
        cmndSRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/cmnds`),
        licenseImageRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/license`),
        licenseSImageRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/licenses`),
        anhBienSoXeRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/biensoxe`),
        regCarImageRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/regcar`),
        regCarsImageRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/regcars`),
        audioRef: (uid) => stRef(storage, `users/${uid}/driverDocuments/audio`),

        singleBookingRef: (bookingKey) => ref(db, "bookings/" + bookingKey),
        bookingSurchargeRef: (bookingKey) => ref(db, "bookings/" + bookingKey + "/surcharges"),
        bookingSurchargeEditRef: (bookingKey, surchargeId) => ref(db, "bookings/" + bookingKey + "/surcharges/" + surchargeId),
        countTimeRef: (bookingKey) => ref(db, "bookings/" + bookingKey + "/count_time"),
        countTimestampRef: (bookingKey) => ref(db, "bookings/" + bookingKey + "/count_time_stamp"),
        bookingByBAUniqueRef: (currentTimeHasGuest) => query(ref(db, "bookings"), orderByChild("currentTimeHasGuest"), equalTo(currentTimeHasGuest)),
        driverAccepted: (bookingKey) => ref(db, "bookings/" + bookingKey + "/driver"),
        bookingDataCarRef: (bookingKey) => ref(db, "bookings/" + bookingKey + "/dataCar"),
        requestedDriversRef: (bookingKey) => ref(db, "bookings/" + bookingKey + "/requestedDrivers"),
        walletBalRef: (uid) => ref(db, "users/" + uid + "/walletBalance"),
        walletHistoryRef: (uid) => ref(db, "users/" + uid + "/walletHistory"),
        driverFavouriteRef: (uid) => ref(db, "users/" + uid + "/driver_favourite"),
        isOnlineRef: (uid) => ref(db, "users/" + uid + "/isOnline"),
        pushTokenRef: (uid) => ref(db, "users/" + uid + "/pushToken"),
        driverFavouriteDriverIdRef: (uid, driverId) => ref(db, "users/" + uid + "/driver_favourite/" + driverId),
        setDriverFavouriteRatingRef: (uid, driverId) => ref(db, "users/" + uid + "/driver_favourite/" + driverId + '/rate'),
        userGroupDriverRef: (uid) => ref(db, "users/" + uid + "/groupDriver"),
        referralIdRef: (referralId) => query(ref(db, "users"), orderByChild("referralId"), equalTo(referralId)),
        trackingRef: (bookingId) => ref(db, 'tracking/' + bookingId),
        binhanhTrackingApiRef: () => ref(db, 'binhanh_tracking_api/'),
        binhanhTrackingCarApiRef: (bsx) => ref(db, 'binhanh_tracking_api/data/' + bsx),
        bookingTrackingRef: () => ref(db, 'booking_tracking/'),
        binhanhTrackingDataApiRef: () => ref(db, 'binhanh_tracking_api/'),
        tasksRef: () => query(ref(db, 'bookings'), orderByChild('status'), equalTo('NEWV2')),
        tasksNewAcceptedRef: () => query(ref(db, 'bookings'), orderByChild('status'), equalTo('NEWV2')),
        singleTaskRef: (uid, bookingId) => ref(db, "bookings/" + bookingId + "/requestedDrivers/" + uid),
        singleTaskDriverRef: (driverCode, bookingId) => ref(db, "bookings/" + bookingId + "/pushed_drivers/" + driverCode),
        singleTaskDriverInRef: (driverCode, bookingId) => ref(db, "bookings/" + bookingId + "/pushed_in_drivers/" + driverCode),
        singleTaskDriverAcceptRef: (bookingId) => ref(db, "bookings/" + bookingId + "/driver_accepted/"),
        singleTaskAcceptUidRef: (bookingId, uid) => ref(db, "bookings/" + bookingId + "/driver_accepted" + '/' + uid + '/'),
        singleBookNotifyRef: (bookingId) => ref(db, "bookings/" + bookingId + "/notify_to_center"),
        singleBookingByCodeRef: (trip_code) => query(ref(db, "bookings/"), orderByChild("trip_code"), equalTo(trip_code)),
        listBookingPendingTypeRef: () => query(ref(db, "bookings/"), orderByChild("status"), equalTo('BOOKING_LATER')),
        listBookingByDriverCodeRef: (code) => query(ref(db, "bookings/"), orderByChild("codeDriver"), equalTo(code)),
        areaWalletRef: (areaId) => ref(db, "wallets/" + areaId),
        bookingByIdRef: (bookingId) => ref(db, "bookings/" + bookingId),
        bookingListRef: (uid, role) =>
            role == 'rider' ? query(ref(db, 'bookings'), orderByChild('customer'), equalTo(uid)) :
                (role == 'driver' ?
                        query(ref(db, 'bookings'), orderByChild('driver'), equalTo(uid))
                        :
                        (role == '' ?
                                //ref(db,'bookings').orderByChild('fleetadmin').equalTo(uid)
                                ref(db, 'bookings')
                                : ref(db, 'bookings')
                        )
                ),

        bookingAllListRef: () => ref(db, 'bookings'),
        bookingAllListByPickupTimeRef: (name, startAtI, endAtI, limit) => query(ref(db, 'bookings'), ...[orderByChild(name), startAt(startAtI), endAt(endAtI), limitToLast(limit)]),
        bookingTrackingByPickupTimeRef: (name, startAtI, endAtI, limit) => query(ref(db, 'booking_tracking'), ...[orderByChild(name), startAt(startAtI), endAt(endAtI), limitToLast(limit)]),

        bookingListTeamRef: (uid) => query(ref(db, 'bookings'), orderByChild('customer'), equalTo(uid)),
        driverMistakeRef: (driver_code) => query(ref(db, 'mistakes'), orderByChild('driver_code'), equalTo(driver_code)),
        driverByPushTokenRef: (pushToken) => query(ref(db, 'users'), orderByChild('pushToken'), equalTo(pushToken)),
        listAreaRef: () => ref(db, "driver_group"),
        displayAreaRef: (id) => ref(db, "driver_group/" + id + '/display_area'),
        getDriverGroupAreaByIdRef: (id) => ref(db, "driver_group/" + id),
        getAreaByIdRef: (areaId) => ref(db, "areas/" + areaId),
        searchAreaByDisplayRef: (name) => query(ref(db, 'driver_group'), ...[orderByChild('display_area'), startAt(name), endAt(name + "\uf8ff")]),
        /*searchAreaByLeaderRef: (leaderId) => ref(db,'driver_group').orderByChild('leaders/' + leaderId).equalTo('pending'),*/
        searchAreaByLeaderRef: (leaderId) => query(child(ref(db, 'areas'), "leaders"), orderByChild(leaderId), equalTo('pending')),
        areasRef: (leaderId) => ref(db, 'areas'),
        bookingAllRef: () => ref(db, 'bookings'),
        chatRef: (bookingId) => ref(db, 'chats/' + bookingId + '/messages'),
        chatMessageRef: (bookingId, chatSessionId, chatUId) => ref(db, 'chats/' + bookingId + '/messages/' + chatSessionId + '/' + chatUId + '/message'),
        withdrawRef: ref(db, 'withdraws/'),

        giaoxeByIdRef: (id) => ref(db, "giaoxe/" + id),
        giaoxeAllRef: ref(db, "giaoxe"),
        giaoxeContentRef: (id) => ref(db, "giaoxe/" + id + "/content"),
        giaoxeByBienSoRef: (bienso) => query(ref(db, "giaoxe"), orderByChild('bienso'), equalTo(bienso)),
        giaoxeByLxNhanSoRef: (lx_nhan) => query(ref(db, "giaoxe"), orderByChild('lx_nhan'), equalTo(lx_nhan)),
        giaoxeByLxGiaoRef: (lx_giao) => query(ref(db, "giaoxe"), orderByChild('lx_giao'), equalTo(lx_giao)),

        forcenotifyAllRef: ref(db, "force_notify"),
        forcenotifyByIdRef: (id) => ref(db, "force_notify/" + id),
        getAddressMapFromNoteRef: (key) => ref(db, "note_map_address/" + key),
        noteMapAddressRef: () => ref(db, "note_map_address/"),
        noteMapAddressByIdRef: (uid = null) => query(ref(db, "note_map_address"), orderByChild('uid'), equalTo(uid)),
        newsAllRef: ref(db, "news"),
        newsByIdRef: (id) => ref(db, "news/" + id),
        newsByIdAddByRef: (id) => query(ref(db, "news"), orderByChild('add_by'), equalTo(id)),
        pricesRef: (uid) => ref(db, "prices/" + uid),
        pricesOpenRef: (uid) => ref(db, "prices/" + uid + "/open"),
        pricesAddRef: (uid) => ref(db, "prices/" + uid + "/add"),
        pricesLimitTimeRef: (uid) => ref(db, "prices/" + uid + "/limit_time_two_way"),
        price1Ref: (uid) => ref(db, "prices/" + uid + "/price1"),
        price2Ref: (uid) => ref(db, "prices/" + uid + "/price2"),
        price1ItemRef: (uid, price1ItemId) => ref(db, "prices/" + uid + "/price1/" + price1ItemId),
        price2ItemRef: (uid, price2ItemId) => ref(db, "prices/" + uid + "/price2/" + price2ItemId),
        price1Item2Ref: (uid, carType, index, type) => ref(db, "prices/" + uid + "/" + carType + "/price1/" + index + "/" + type),
        pricesOpenAreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/open"),
        pricesAddAreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/add"),
        price1AreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/price1"),
        price2AreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/price2"),
        price1ItemAreaRef: (uid_cartypecode_prices, price1ItemId) => ref(db, "areas/" + uid_cartypecode_prices + "/price1/" + price1ItemId),
        price2ItemAreaRef: (uid_cartypecode_prices, price2ItemId) => ref(db, "areas/" + uid_cartypecode_prices + "/price2/" + price2ItemId),
        minAreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/min_total"),
        freeAreaRef: (uid_cartypecode_prices) => ref(db, "areas/" + uid_cartypecode_prices + "/free"),

        priceTimeStoneItemAreaRef: (uid, price1ItemId) => ref(db, "areas/" + uid + "/pricestimestone/" + price1ItemId),
        priceTimeStoneAreaRef: (uid) => ref(db, "areas/" + uid + "/pricestimestone"),

        tradeRef: (key) => ref(db, 'trades/' + key),
        noteListRef: () => ref(db, 'note_list'),
        noteListPhoneRef: (phone) => ref(db, 'note_list/' + phone),
        historyMarketsRef: (id) => ref(db, 'markets/' + id + "/transactions"),
        marketByIdRef: (id) => ref(db, 'markets/' + id),
        marketPointsByIdRef: (id) => ref(db, 'markets/' + id + "/points"),
        marketStatusByIdRef: (id) => ref(db, 'markets/' + id + "/status"),
        marketsRef: () => ref(db, "markets"),

        driverGroupByIdRef: (id) => ref(db, "driver_group/" + id),
        driverFaviouriteRef: (uid) => ref(db, "prices/" + uid),

        pointsRef: (uid) => ref(db, 'points/' + uid),
        pointHistoryRef: (uid) => ref(db, 'points/' + uid + '/history'),

        getDynamicByPatchRef: (ref) => ref(db, ref),

        reportsRef: (uid) => ref(db, 'reports/' + uid),
        reportsDateRef: (uid, date_key) => ref(db, 'reports/' + uid + '/' + date_key),
        reportItemRef: (uid, date_key, type_report) => ref(db, 'reports/' + uid + '/' + date_key + "/" + type_report),
        reportObjectRef: (uid, date_key, type_report) => ref(db, 'reports/' + uid + '/' + date_key),
        friendRef: (uid) => ref(db, 'friends/' + uid),
        friendDetailRef: (uid, uidFriend) => ref(db, 'friends/' + uid + "/" + uidFriend),
        friendDetailStatusRef: (uid, uidFriend) => ref(db, 'friends/' + uid + "/" + uidFriend + '/status'),
        caDemConfigRef: () => ref(db, 'ca_dem_config'),
        groupdriverTotalRef: (id) => ref(db, "group_driver/" + id + "/totalDriver"),
        groupdriverPricesRef: (id) => ref(db, "group_driver/" + id + "/prices"),
        setBookingPushedDriverRef: (id) => ref(db, "bookings/" + id + "/pushed_in_drivers"),
        groupdriverCoordTotalRef: (groupId, coordId) => ref(db, "group_driver/" + groupId + "/group_coords/" + coordId + "/totalDriver"),
        rolePermissionRef: ref(db, "role_permission"),
        rolePermissionItemRef: (id) => ref(db, "role_permission/" + id),
        rolePermissionMemberRef: (parentId) => ref(db, "role_permission/" + parentId + "/members"),
        rolePermissionItemMemberRef: (parentId, id) => ref(db, "role_permission/" + parentId + "/members/" + id),
        vipReportRef: () => ref(db, "vip_report"),
        vipReportIdRef: (id) => ref(db, "vip_report/" + id),
        getVipByUid: (code) => query(ref(db, "vip_report"), orderByChild("codeDriver"), equalTo(code)),
        trackingApiRef: (bookingId) => ref(db, 'tracking/' + bookingId),
        driverOpenCodeRef: ref(db, 'drivers_open/codes'),
        triggerActionRef: (uid, status) => query(ref(db, "trigger_action/" + uid), orderByChild("status"), equalTo(status)),
        bookingByCustomerPhoneRef: (customer_contact) => query(ref(db, 'bookings'), ...[orderByChild('customer_contact'), equalTo(customer_contact), limitToLast(5)]),
        allLocationsRef: ref(db, "locations"),
        userLocationRef: (uid) => ref(db, "locations/" + uid),
        driversRef: query(ref(db, "users"), orderByChild("usertype"), equalTo("driver")),
        connectedRef: ref(db, ".info/connected"),
        bookingNotiRef: () => ref(db, "booking_noti"),
        bookingNotiIdRef: (id) => ref(db, "booking_noti/" + id),
        refApi: createApiStructure(db)
    }
}
const FirebaseProvider = ({config, children, AsyncStorage}) => {
    let app, auth, database, storage;
    if (!getApps().length) {
        try {
            app = initializeApp(config);
            if (typeof document !== 'undefined') {
                auth = initializeAuth(app, {
                    persistence: browserLocalPersistence,
                    popupRedirectResolver: browserPopupRedirectResolver,
                });
            } else {
                auth = initializeAuth(app, {
                    persistence: getReactNativePersistence(AsyncStorage),
                });
            }
            database = getDatabase(app);
            try {
                database.persistenceEnabled = true;
            } catch (e) {
                console.log("e", e);
            }
            storage = getStorage(app);
        } catch (error) {
            console.log("Error initializing app: " + error);
        }
    } else {
        app = getApp();
        auth = getAuth(app);
        database = getDatabase(app);
        try {
            database.persistenceEnabled = true;
        } catch (e) {
            console.log("e", e);
        }
        storage = getStorage(app);
    }

    firebase = createFullStructure(app, database, auth, storage, config);

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}

export {
    firebase,
    FirebaseProvider,
    FirebaseContext
}
