import React, {useEffect, useMemo, useState} from 'react';
import TextField from '@mui/material/TextField';
import {Autocomplete} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {geocodeByPlaceId} from 'react-places-autocomplete';
import {useSelector} from "react-redux";
import Box from '@mui/material/Box';
import {colors} from "../config/theme";
import {getAddressDefaultAutoCompleted} from "common/src/other/MapboxAPIFunctions";

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    iconRtl: {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2),
    },
    inputRtl: {
        "& label": {
            right: 75,
            left: "auto"
        },
        "& legend": {
            textAlign: "right",
            marginRight: 60
        }
    },
    textField: {
        "& label.Mui-focused": {
            color: colors.BLACK,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.TRANSPARENT,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.TRANSPARENT,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.TRANSPARENT,
            },
        },
    },

}));

export default function MapboxAutoComplete(props) {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const settingsdata = useSelector(state => state.settingsdata);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (settingsdata.settings) {
            setSettings(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        getAddressDefaultAutoCompleted(inputValue, settings.mb_token_pk).then((res) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }
                if (res) {
                    newOptions = [...newOptions, ...res];
                }

                setOptions(newOptions);
            }
        });


        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            style={props.style}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.place_name)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            readOnly={props.readOnly ? props.readOnly : false}
            includeInputInList
            filterSelectedOptions
            value={props.value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (newValue && newValue.center) {
                    console.log('newValue.center[0]', newValue);
                    newValue.coords = {lat: newValue.center[1], lng: newValue.center[0]}
                    newValue.placeDetails = newValue.place_name;
                    props.onChange(newValue);
                } else {
                    console.log("ko xác định toạ độ");
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={props.variant}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    className={[classes.commonInputStyle, classes.textField].join(" ")}
                    style={props.textFieldStyle ? props.textFieldStyle : null}
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {fontSize: 12},
                        '& .MuiInputBase-root': {
                            backgroundColor: '#f0f0f0',  // Set background color of the input
                            borderRadius: 1,             // Adjust border radius if needed
                            border: 'none',              // Remove border
                            padding: 0.1
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',              // Ensures no outline on focus
                        }
                    }}
                />
            )}

            renderOption={(props, option) => {
                const matches =
                    option.place_name || [];

                /* const parts = parse(
                     option.place_name,
                     matches.map((match) => [match.offset, match.offset + match.length]),
                 );
 */
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{display: 'flex', width: 44}}>
                                <LocationOnIcon sx={{color: 'text.secondary'}}/>
                            </Grid>
                            <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                <Box
                                    component="span"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    {option.place_name}
                                </Box>

                                <Typography variant="body2" color="text.secondary">
                                    {option.center.join(",")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}