import {EDIT_CAR_TYPE, EDIT_CAR_TYPE_PRICE, FETCH_CAR_TYPES, FETCH_CAR_TYPES_FAILED, FETCH_CAR_TYPES_SUCCESS, FETCH_PRICES_SUCCESS, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";
import {language} from 'config';
import store from "../store/store";
import {firebase} from '../config/configureFirebase';
import {off, onValue, set, update} from "firebase/database";
import {saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const fetchCarTypes = () => (dispatch) => {

    const {
        carTypesRef
    } = firebase;

    dispatch({
        type: FETCH_CAR_TYPES,
        payload: null
    });
    off(carTypesRef);
    onValue(carTypesRef, async snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].label = i;
                data[i].id = i;
                return data[i]
            });
            dispatch({
                type: FETCH_CAR_TYPES_SUCCESS,
                payload: arr
            });
            await saveDbFromFbToLocal(FETCH_CAR_TYPES_SUCCESS, arr);
            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });
        } else {
            dispatch({
                type: FETCH_CAR_TYPES_FAILED,
                payload: language.no_cars
            });
        }
    }, error => {
        //throw new error;
    });
};

export const editCarType = (carTypes, method, index) => (dispatch) => {
    const {
        carTypesRef,
        singleUserRef,
        carTypesEditRef
    } = firebase;

    dispatch({
        type: EDIT_CAR_TYPE,
        payload: method
    });

    if (method === 'Update' && carTypes.name) {
        let cars = store.getState().cartypes.cars;
        if (index || index === 0) {
            let carName = cars[index].name;
            update(carTypesEditRef(index), carTypes).then(() => {
                let users = store.getState().usersdata.users;
                if (users && users.length > 0) {
                    for (let i = 0; i < users.length; i++) {
                        let usr = users[i];
                        if (usr.carType && usr.carType === carName && usr.id) {
                            let userEdit = {
                                carType: carTypes.name,
                                carTypeCode: cars[index].code
                            }
                            update(singleUserRef(usr.id), userEdit);
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    } else {
        set(carTypesRef, carTypes);
    }
}

export const editCartypePrice2 = (id, carTypePrice, method) => (dispatch) => {

    const {
        carTypesPriceRef
    } = firebase;

    dispatch({
        type: EDIT_CAR_TYPE_PRICE,
        payload: method
    });
    set(carTypesPriceRef(id), carTypePrice);
}

