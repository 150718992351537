import {ACCEPT_TASK, ASSIGN_TASK, CANCEL_TASK, FETCH_ACCEPTED_TASKS, FETCH_ACCEPTED_TASKS_FAILED, FETCH_ACCEPTED_TASKS_SUCCESS, FETCH_TASKS, FETCH_TASKS_FAILED, FETCH_TASKS_SUCCESS, UPDATE_SESSION_TOTAL_LOAD, UPDATE_TRACKING} from "../store/types";
import {language} from 'config';
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "../other/DateFunctions";
import {RequestPushMsg} from "../other/NotificationFunctions";
import store from "../store/store";
import {GetDistance} from "../other/GeoFunctions";
import {congthuc1chieu, congthuc2chieu} from "../other/FareCalculator";
import {getDriveTime} from "../other/GoogleAPIFunctions";
import {child, limitToLast, off, onValue, push, query, remove, set, update} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {pushPoint} from "./pointsactions";

export const fetchAcceptedTasks = () => (dispatch) => {
    const {
        auth,
        tasksNewAcceptedRef
    } = firebase;
    off(tasksNewAcceptedRef());
    if (auth && auth.currentUser && auth.currentUser.uid) {
        dispatch({
            type: FETCH_ACCEPTED_TASKS,
            payload: null
        });
        onValue(tasksNewAcceptedRef(), (snapshot) => {
            if (snapshot.val()) {
                const active = [];
                const data = snapshot.val();
                const bookings = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                        return data[i];
                    });
                for (let i = 0; i < bookings.length; i++) {
                    if (bookings[i].driver_accepted && bookings[i].driver_accepted[auth.currentUser.uid]) {
                        active.push(bookings[i])
                    }
                }
                dispatch({
                    type: FETCH_ACCEPTED_TASKS_SUCCESS,
                    payload: active
                });
            } else {
                dispatch({
                    type: FETCH_ACCEPTED_TASKS_FAILED,
                    payload: language.no_tasks,
                });
            }
        });
    }
}

export const fetchTasks = (role, uid = null) => (dispatch) => {
    const {
        auth,
        tasksRef
    } = firebase;
    let id = uid ? uid : auth.currentUser && auth.currentUser.uid ? auth.currentUser && auth.currentUser.uid : null;
    if (auth && id) {
        dispatch({
            type: FETCH_TASKS,
            payload: null
        });
        onValue(tasksRef(), (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const bookings = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                        return data[i];
                    });
                let listTask = [];
                if (role === 'driver' && id) {
                    for (let i = 0; i < bookings.length; i++) {
                        if (bookings[i].customer != id || !bookings[i].customer) {
                            if (bookings[i] && bookings[i].source != 'KQ' && bookings[i].requestedDrivers && bookings[i].requestedDrivers[id]) {
                                listTask.push(bookings[i]);
                            }
                        } else {
                            // Dat ho
                            if (bookings[i] && bookings[i].source != 'KQ' && bookings[i].requestedDrivers && bookings[i].customer && bookings[i].customer == id && bookings[i].requestedDrivers[id]) {
                                listTask.push(bookings[i]);
                            }
                        }
                    }
                    if (listTask && listTask.length > 0) {
                        dispatch({
                            type: FETCH_TASKS_SUCCESS,
                            payload: listTask
                        });
                        const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
                        dispatch({
                            type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
                        });
                    } else {
                        dispatch({
                            type: FETCH_TASKS_FAILED,
                            payload: language.no_tasks
                        });
                    }

                } else {
                    if (id && role === 'rider' && bookings[0].requestedDrivers && bookings[0].customer == id) {
                        dispatch({
                            type: FETCH_TASKS_SUCCESS,
                            payload: [bookings[0]]
                        });
                        const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
                        dispatch({
                            type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
                        });
                    } else {
                        dispatch({
                            type: FETCH_TASKS_FAILED,
                            payload: language.no_tasks,
                        });
                    }
                }
            } else {
                dispatch({
                    type: FETCH_TASKS_FAILED,
                    payload: language.no_tasks,
                });
            }
        }, error => {
            //throw new error;
        });
    }
};

export const stopFetchTasks = () => {
    const {
        tasksRef
    } = firebase;
    off(query(tasksRef(), limitToLast(1)));
};


export const acceptTask = (userAuthData, task) => async (dispatch) => {
    const {
        singleTaskRef,
        trackingRef,
        singleTaskAcceptUidRef,
        getVipByUid
    } = firebase;

    var driverPrices = null;
    if (store.getState().pricesdata) {
        driverPrices = store.getState().pricesdata.prices;
    }

    var prices = null;
    if (task.prices) {
        prices = task.prices
    } else {
        if (driverPrices && task.carTypeCode && driverPrices[task.carTypeCode]) {
            prices = driverPrices[task.carTypeCode];
        }
    }
    if (task.id && userAuthData) {
        await remove(singleTaskRef(userAuthData.uid, task.id));
        let dataTracking = {
            at: GetCurrentMiniTimeStamp(),
            status: 'ACCEPTED',
            lat: userAuthData.location.lat,
            lng: userAuthData.location.lng,
            uid: userAuthData.uid
        };
        push(trackingRef(task.id), dataTracking);
        let cars = store.getState().cartypes.cars;
        let carSelect = cars.filter((car) => {
            if (car.name === task.carType) {
                return true;
            }
        });
        console.log("accepted 1")
        let pickup = task.pickupFrom ? task.pickupFrom : task.pickup;

        if (pickup && pickup.lat) {
            let startLoc = userAuthData.location.lat + ',' + userAuthData.location.lng;
            let destLoc = pickup.lat + ',' + pickup.lng;
            console.log("accepted 2")
            // TODO bo bao gia lx nhan
            try {
                //let arriveTime = await getDriveTime(startLoc, destLoc);
                let arriveTime = null;
                var distanceEstimate = GetDistance(userAuthData.location.lat, userAuthData.location.lng, pickup.lat, pickup.lng);
                var timeEstimate = (distanceEstimate / 35) * 60;
                if (arriveTime && arriveTime.distance_in_km && arriveTime.time_in_secs) {
                    distanceEstimate = arriveTime.distance_in_km;
                    timeEstimate = arriveTime.time_in_secs;
                }
            } catch (e) {
                console.log(e);
            }
        }
        console.log("accepted4")
        var estimate = null;
        if (task.type != "LX" && driverPrices && task.drop && task.drop.lat && task.source != "TT"
            && task.estimateTemp) {
            try {
                let arriveTime2 = task.estimateTemp;
                var distanceEstimate2 = arriveTime2.distance_in_km;
                var timeEstimate2 = arriveTime2.time_in_secs;
                if (prices) {
                    estimate = congthuc1chieu(parseFloat(distanceEstimate2), 0, null, prices);
                }
                if (task.is_two_way && task.is_two_way === true) {
                    if (prices) {
                        estimate = congthuc2chieu(parseFloat(distanceEstimate2) * 2, 0, null, 0, prices);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        var vip = null;
        let listVipSnapshot = await onValue(getVipByUid(userAuthData.codeDriver), listVipSnapshot => {
            if (listVipSnapshot) {
                let listVip = listVipSnapshot.val();
                if (listVip) {
                    let vips = Object.keys(listVip)
                        .filter(key => listVip[key].valueStatus === 'tang')
                        .map((i) => {
                            listVip[i].id = i;
                            return listVip[i];
                        });


                    if (vips && vips.length > 0) {
                        vips = vips.sort(function (a, b) {
                            if (!b.at) {
                                b.at = GetCurrentMiniTimeStamp();
                            }
                            if (!a.at) {
                                a.at = GetCurrentMiniTimeStamp();
                            }
                            return (parseFloat(a.at) - parseFloat(b.at));
                        });

                        vip = vips[0];
                    }
                }
            }

            let acceptObj = {
                time_accept: GetCurrentMiniTimeStamp(),
                code: userAuthData.codeDriver,
                phone: userAuthData.mobile.replace("+84", ''),
                name: userAuthData.firstName + " " + userAuthData.firstName,
                vehicle_number: userAuthData.vehicleNumber ? userAuthData.vehicleNumber : '',
                avatar: userAuthData.avatar ? userAuthData.avatar : '',
                vehicleModel: userAuthData.vehicleModel ? userAuthData.vehicleModel : '',
                totalBook: userAuthData.totalBook ? userAuthData.totalBook : 0,
                per: userAuthData.per && userAuthData.driverVipStatus ? userAuthData.per : 0,
                perUse: userAuthData.perUse && userAuthData.perUse ? userAuthData.perUse : 0,
                contact_limited: userAuthData.contact_limited ? userAuthData.contact_limited : 0,
                vip_limit: userAuthData.vip_limit ? userAuthData.vip_limit : 0,
                signupViaReferral: userAuthData.signupViaReferral ? userAuthData.signupViaReferral : '',
                rate: userAuthData.ratings && userAuthData.ratings.userrating ? userAuthData.ratings.userrating : 0,
                lat: userAuthData.location.lat,
                carType: carSelect.type ? carSelect.type : 'taxi',
                distanceEstimate: distanceEstimate ? distanceEstimate : 0,
                timeEstimate: timeEstimate ? timeEstimate : 0,
                wallet: userAuthData.walletBalance ? userAuthData.walletBalance : 0,
                distanceEstimate2: distanceEstimate2 ? distanceEstimate2 : 0,
                timeEstimate2: timeEstimate2 ? timeEstimate2 : 0,
                estimate: estimate ? estimate : 0,
                lng: userAuthData.location.lng,
                uid: userAuthData.uid,
                indexGroup: userAuthData.indexGroup ? userAuthData.indexGroup : 0,
                driverVipStatus: userAuthData.driverVipStatus ? userAuthData.driverVipStatus : false,
                prices: prices ? prices : null,
                driverType: userAuthData.driverType ? userAuthData.driverType : 'noi_bo',
                id: userAuthData.uid,
                pushToken: userAuthData.pushToken,
                timestamp: userAuthData.timestampingroup ? userAuthData.timestampingroup : GetCurrentTimeStamp(),
                vipAt: vip ? vip.at : GetCurrentMiniTimeStamp()
            }

            set(singleTaskAcceptUidRef(task.id, userAuthData.uid), acceptObj).then((r) => {
                let bookingKey = task.id;
                //TODO :  source LX
                if (task.source === "TT" || task.type === "TT" || task.source === "LX" || task.type === "LX" || task.prices) {
                    dispatch({
                        type: UPDATE_TRACKING,
                        payload: {
                            tracking: {
                                at: GetCurrentMiniTimeStamp(),
                                status: 'ACCEPTED',
                                lat: userAuthData.location.lat,
                                lng: userAuthData.location.lng,
                                uid: userAuthData.uid
                            }
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        }, {onlyOnce: true});

    }
    dispatch({
        type: ACCEPT_TASK,
        payload: {task: task},
        error_messenge: ''
    });
};


export const startBookingLaterApi = (profile, booking) => {
    const {
        singleBookingRef,
        singleUserRef,
        chatRef,
        paCallByIdRef,
        reportdriverRef
    } = firebase;
    var newData = {};
    var taskUpdate = {};
    let dt = new Date();
    let localString = ConvertTimeStampToDate(GetCurrentMiniTimeStamp());
    newData.content = localString + " [" + booking.customer_note + "] Tài xế #" + profile.codeDriver + " bắt đầu đón khách #" + booking.id;
    push(reportdriverRef, newData);
    update(singleUserRef(profile.id), {
        queue: true,
        cr_bookingid: booking.id,
        groupDriver: 9,
        currentGroup: profile.groupDriver
    });
    taskUpdate.driver = profile.id;
    taskUpdate.driver_image = profile.profile_image ? profile.profile_image : "";
    taskUpdate.driver_name = profile.firstName + ' ' + profile.lastName;
    taskUpdate.driver_contact = profile.mobile;
    taskUpdate.driver_token = profile.pushToken;
    taskUpdate.driver_bankCode = profile.bankCode;
    taskUpdate.driver_bankAccount = profile.bankAccount;
    taskUpdate.vehicle_number = profile.vehicleNumber;
    taskUpdate.codeDriver = profile.codeDriver;
    taskUpdate.driverRating = profile.ratings ? profile.ratings.userrating : "0";
    taskUpdate.fleetadmin = profile.fleetadmin ? profile.fleetadmin : '';
    taskUpdate.accept_time = GetCurrentMiniTimeStamp();
    taskUpdate.notify_to_center = localString + " Tài xế #" + profile.codeDriver + " Đã nhận #" + booking.id;
    taskUpdate.status = "ACCEPTED";
    taskUpdate.count_time = "LAI_XE_DA_NHAN";
    taskUpdate.driverType = profile.driverType;
    taskUpdate.driverCurrentAddress = profile.location;
    taskUpdate.isDriverNotMistake = false;
    taskUpdate.customer_wish = false;
    taskUpdate.driver_accepted = null;
    update(singleBookingRef(booking.id), taskUpdate).then(r => {
        if (booking.customer && profile.id) {
            const chatId = booking.customer + ',' + profile.id;
            var time = dt.getHours() + ":" + dt.getMinutes();
            const msg = {
                message: (booking.customer_note ? booking.customer_note : ''),
                from: booking.customer,
                type: "msg",
                msgDate: dt,
                msgTime: time,
                source: 'rider'
            };
            push(child(chatRef(booking.id), chatId), msg);
            if (booking.customer_token) {
                RequestPushMsg(booking.customer_token, language.notification_title, taskUpdate.codeDriver + " " + language.accept_booking_request);
            }
            if (booking.callId) {
                let data = {
                    status: "accept",
                    accept_driver: profile.id,
                    driver_code: profile.codeDriver,
                    driver_phone: profile.mobile.replace("+840", "0"),
                    accept_time: GetCurrentMiniTimeStamp(),
                };
                update(paCallByIdRef(booking.callId), data);
            }
        }
    }).catch(error => {
        console.log(error);
    });
};

export const assignTask = (task, uid) => (dispatch) => {
    const {
        singleBookingRef,
        singleUserRef,
        chatRef,
        paCallByIdRef,
        reportdriverRef,
        trackingRef,
        singleTaskDriverAcceptRef
    } = firebase;
    let dt = new Date();
    var prices = null;
    if (task.prices) {
        prices = task.prices
    } else {
        if (task.driver_accepted && task.driver_accepted[uid] && task.driver_accepted[uid].prices) {
            prices = task.driver_accepted[uid].prices;
        }
    }
    onValue(singleTaskDriverAcceptRef(task.id), snapshot => {
        let driverAccepted = snapshot.val();
        Object.values(driverAccepted).map((driver) => {
            if (driver.uid !== uid) {
                onValue(singleUserRef(driver.uid), res => {
                    let profile = res.val();
                    let dataPoint = {
                        point: 1,
                        status: 'success',
                        uid: driver.uid,
                        note: 'Nhận cuốc khách #' + (task.trip_code ? task.trip_code : ''),
                        date_add: GetCurrentTimeStamp(),
                        type: 'accepted_booking'
                    }
                    dispatch(pushPoint(driver.uid, dataPoint));

                    /*let currentBalance = profile.walletBalance;
                    walletBalRef(driver.uid).set(currentBalance + 100000).then(() => {
                        let details = {
                            type: 'Refund',
                            amount: 100000,
                            date: GetCurrentMiniTimeStamp(),
                            txRef: task.id ? task.id : GetCurrentMiniTimeStamp(),
                            transaction_id: generateTripCode(10)
                        }
                        walletHistoryRef(driver.uid).push(details).then(() => {
                            RequestPushMsg(profile.pushToken, language.notification_title, "Tiền tạm giữ cuốc khách đã được hoàn trả");
                        })
                    })*/
                }, {onlyOnce: true});
            }
        }, {onlyOnce: true});
    });


    onValue(singleUserRef(uid), snapshot => {
        var taskUpdate = {};
        if (snapshot.val()) {
            let profile = snapshot.val();

            let dataTracking = {
                at: GetCurrentMiniTimeStamp(),
                status: 'ACCEPTED',
                lat: profile.location.lat,
                lng: profile.location.lng,
                uid: uid
            };
            push(trackingRef(task.id), dataTracking);

            let newPer = (profile.totalBook ? profile.totalBook : 1) + 1;
            var newData = {};
            newData.content = ConvertTimeStampToDate(GetCurrentMiniTimeStamp()) + " [" + task.customer_note + "] Khách đã chọn #" + profile.codeDriver + " cuốc khách #" + task.id;
            push(reportdriverRef, newData);
            update(singleUserRef(uid), {
                totalBook: newPer,
                queue: true,
                cr_bookingid: task.id,
                groupDriver: 9,
                currentGroup: profile.groupDriver
            });
            taskUpdate.driver = uid;
            taskUpdate.driver_image = profile.avatar ? profile.avatar : "";
            taskUpdate.driver_name = profile.firstName + ' ' + profile.lastName;
            taskUpdate.driver_contact = profile.mobile;
            taskUpdate.driver_token = profile.pushToken;
            taskUpdate.driver_bankCode = profile.bankCode;
            taskUpdate.driver_bankAccount = profile.bankAccount;
            taskUpdate.driver_location = {
                at: GetCurrentMiniTimeStamp(),
                lat: profile.location.lat,
                lng: profile.location.lng,
            };
            taskUpdate.vehicle_number = profile.vehicleNumber;
            taskUpdate.codeDriver = profile.codeDriver;
            taskUpdate.driverRating = profile.ratings ? profile.ratings.userrating : "0";
            taskUpdate.fleetadmin = profile.fleetadmin ? profile.fleetadmin : '';
            taskUpdate.accept_time = GetCurrentMiniTimeStamp();
            taskUpdate.notify_to_center = newData.content;
            taskUpdate.status = "ACCEPTED";
            taskUpdate.count_time = "LAI_XE_DA_NHAN";
            taskUpdate.driverType = profile.driverType;
            taskUpdate.isDriverNotMistake = false;
            taskUpdate.customer_wish = false;
            taskUpdate.prices = prices ? prices : null;

            update(singleBookingRef(task.id), taskUpdate).then(r => {

                /*let dataNotify = {
                    date_add: GetCurrentTimeStamp(),
                    status: 'pending',
                    from: task.customer,
                    type: 'booking_driver_accepted',
                    title: "Cuốc khách đang vận hành",
                    booking_id: task.id,
                    content: "Cuốc khách " + (task.note ? "[" + task.note + "]" : "") + " đang vận hành, đến xem thông tin!",
                    to: taskUpdate.driver,
                    params: [task]
                };
                dispatch(api.pushNotifyImportance(dataNotify));*/

                if (task.customer && uid) {
                    const chatId = task.customer + ',' + uid;
                    var time = dt.getHours() + ":" + dt.getMinutes();
                    const msg = {
                        message: (task.customer_note ? task.customer_note : ''),
                        from: task.customer,
                        type: "msg",
                        msgDate: dt,
                        msgTime: time,
                        source: 'rider'
                    };
                    push(child(chatRef(task.id), chatId), msg);
                    if (task.customer_token) {
                        RequestPushMsg(task.customer_token, language.notification_title, taskUpdate.codeDriver + " " + language.accept_booking_request);
                    }
                    if (task.callId) {
                        let data = {
                            status: "accept",
                            accept_driver: uid,
                            driver_code: profile.codeDriver,
                            driver_phone: profile.mobile.replace("+840", "0"),
                            accept_time: GetCurrentMiniTimeStamp(),
                        };
                        update(paCallByIdRef(task.callId), data);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }, {onlyOnce: true});
    dispatch({
        type: ASSIGN_TASK,
        payload: {task: task},
        error_messenge: ''
    });
}

export const acceptTaskLater = (userAuthData, task) => (dispatch) => {
    const {
        singleTaskDriverAcceptRef,
        trackingRef
    } = firebase;
    if (task.id) {
        let dataTracking = {
            at: GetCurrentMiniTimeStamp(),
            status: 'ACCEPTED',
            lat: userAuthData.profile.location.lat,
            lng: userAuthData.profile.location.lng,
            uid: userAuthData.uid
        };
        push(trackingRef(task.id), dataTracking);
        let acceptObj = {
            time_accept: GetCurrentMiniTimeStamp(),
            code: userAuthData.profile.codeDriver,
            phone: userAuthData.profile.mobile.replace("+84", ''),
            name: userAuthData.profile.firstName + " " + userAuthData.profile.firstName,
            totalBook: userAuthData.profile.totalBook ? userAuthData.profile.totalBook : 0,
            lat: userAuthData.profile.location.lat,
            lng: userAuthData.profile.location.lng,
            uid: userAuthData.uid
        }
        push(singleTaskDriverAcceptRef(task.id), acceptObj).then((r) => {
            let bookingKey = task.id;
        }).catch((error) => {
            console.log(error);
        });
    }
    dispatch({
        type: ACCEPT_TASK,
        payload: {task: task},
        error_messenge: ''
    });
};

export const cancelTask = (bookingId) => (dispatch) => {

    const {
        auth,
        singleTaskRef,
        singleUserRef,
        singleBookingRef,
        setBookingPushedDriverRef
    } = firebase;

    const authState = store.getState().auth;
    const uid = auth && auth.currentUser && auth.currentUser ? auth.currentUser.uid : authState && authState.user ? authState.user.id : null;

    if (uid) {
        onValue(singleUserRef(uid), res => {
            let profile = res.val();
            let codeDriver = profile.codeDriver;
            if (codeDriver) {
                onValue(singleBookingRef(bookingId), snapshot => {
                    if (snapshot.val()) {
                        const booking = snapshot.val();
                        if (booking.pushed_in_drivers) {
                            var listDriverPushed = booking.pushed_in_drivers;
                            var listDriverPush2 = listDriverPushed.replace("," + codeDriver, '');
                            var listDriverPush3 = listDriverPush2.replace(codeDriver + ",", '');
                            set(setBookingPushedDriverRef(bookingId), listDriverPush3);
                        }
                    }
                }, {onlyOnce: true});
            }

        }, {onlyOnce: true});

        remove(singleTaskRef(uid, bookingId));

        dispatch({
            type: CANCEL_TASK,
            payload: {uid: uid, bookingId: bookingId},
        });
    }
};

