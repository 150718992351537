import {FETCH_PRICES, FETCH_PRICES_FAILED, FETCH_PRICES_SUCCESS, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {onValue, push, remove, set, update} from "firebase/database";
import store from "../store/store";
import {deleteLocal, saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const fetchPrices = (uid = null) => (dispatch) => {
    const {
        pricesRef,
        auth
    } = firebase;

    dispatch({
        type: FETCH_PRICES,
        payload: null
    });

    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    onValue(pricesRef(uidCheck), async snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_PRICES_SUCCESS,
                payload: snapshot.val()
            });
            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });
            await saveDbFromFbToLocal(FETCH_PRICES_SUCCESS, snapshot.val());
        } else {
            dispatch({
                type: FETCH_PRICES_FAILED,
                payload: "Không tìm thấy bảng giá"
            });
            await deleteLocal(FETCH_PRICES_SUCCESS);
        }
    }, {onlyOnce: true}, error => {
        //throw new error;
    });
};

export const setPriceOpen = (uid, price) => {
    const {
        pricesOpenRef
    } = firebase;

    set(pricesOpenRef(uid), price);
};

export const setAdd = (uid, price) => {
    const {
        pricesAddRef
    } = firebase;

    set(pricesAddRef(uid), price);
};

export const pushPrice1Item = (uid, priceObj) => {
    const {
        price1Ref
    } = firebase;
    push(price1Ref(uid), priceObj);
};

export const setPriceDriver = (uid, listPriceObj) => {
    const {
        price1Ref
    } = firebase;
    set(price1Ref(uid), listPriceObj);
};

export const setPrice2Driver = (uid, listPriceObj) => {
    const {
        price2Ref
    } = firebase;
    set(price2Ref(uid), listPriceObj);
};


export const pushPrice2Item = (uid, priceObj) => {
    const {
        price2Ref
    } = firebase;
    push(price2Ref(uid), priceObj);
};

export const delPrice1Item = (uid, price1ItemId) => {
    const {
        price1ItemRef
    } = firebase;
    remove(price1ItemRef(uid, price1ItemId));
};

export const delPrice2Item = (uid, price2ItemId) => {
    const {
        price2ItemRef
    } = firebase;
    remove(price2ItemRef(uid, price2ItemId));
};

export const editPrice1Item = (uid, price1ItemId, data) => {
    const {
        price1ItemRef
    } = firebase;
    update(price1ItemRef(uid, price1ItemId), data);
};

export const editPrice2Item = (uid, price2ItemId, data) => {
    const {
        price2ItemRef
    } = firebase;
    update(price2ItemRef(uid, price2ItemId), data);
};


export const setPriceOpenArea = (uid, price) => {
    const {
        pricesOpenAreaRef
    } = firebase;

    set(pricesOpenAreaRef(uid), price);
};

export const setAddArea = (uid, price) => {
    const {
        pricesAddAreaRef
    } = firebase;

    set(pricesAddAreaRef(uid), price);
};

export const pushPrice1ItemArea = (uid_cartypecode_prices, priceObj) => {
    const {
        price1AreaRef
    } = firebase;
    push(price1AreaRef(uid_cartypecode_prices), priceObj);
};

export const pushPrice2ItemArea = (uid_cartypecode_prices, priceObj) => {
    const {
        price2AreaRef
    } = firebase;
    push(price2AreaRef(uid_cartypecode_prices), priceObj);
};

export const delPrice1ItemArea = (uid_cartypecode_prices, price1ItemId) => {
    const {
        price1ItemAreaRef
    } = firebase;
    remove(price1ItemAreaRef(uid_cartypecode_prices, price1ItemId));
};

export const delPrice2ItemArea = (uid_cartypecode_prices, price2ItemId) => {
    const {
        price2ItemAreaRef
    } = firebase;
    remove(price2ItemAreaRef(uid_cartypecode_prices, price2ItemId));
};

export const editPrice1ItemArea = (uid_cartypecode_prices, price1ItemId, data) => {
    const {
        price1ItemAreaRef
    } = firebase;
    update(price1ItemAreaRef(uid_cartypecode_prices, price1ItemId), data);
};

export const editPrice2ItemArea = (uid_cartypecode_prices, price2ItemId, data) => {
    const {
        price2ItemAreaRef
    } = firebase;
    update(price2ItemAreaRef(uid_cartypecode_prices, price2ItemId), data);
};

export const pushPriceTimeStoneItemArea = (uid, priceObj) => {
    const {
        priceTimeStoneAreaRef
    } = firebase;
    push(priceTimeStoneAreaRef(uid), priceObj);
};

export const editPriceTimeStoneItemArea = (uid, price1ItemId, data) => {
    const {
        priceTimeStoneAreaRef
    } = firebase;
    update(priceTimeStoneAreaRef(uid, price1ItemId), data);
};

export const delPriceTimeStoneItemArea = (uid, price1ItemId) => {
    const {
        priceTimeStoneAreaRef
    } = firebase;
    remove(priceTimeStoneAreaRef(uid, price1ItemId));
};

export const setFreeArea = (uid, price) => {
    const {
        freeAreaRef
    } = firebase;
    set(freeAreaRef(uid), price);
};

export const setMinTotalArea = (uid, price) => {
    const {
        minAreaRef
    } = firebase;
    set(minAreaRef(uid), price);
};

