import {generateTripCode, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "./DateFunctions";
import {RequestPushMsg} from "./NotificationFunctions";
import {language} from "config";


export function checkDriverValid(phoneCustomer, driver_accepted_array, settings = null, customer_signup_via_referral = null, customer_contact_limited = null, isDev = false) {
    let msg = '';
    let tracking = '';
    if (driver_accepted_array && driver_accepted_array.length > 0) {
        var sorted = driver_accepted_array;
        // VIP
        if (phoneCustomer) {
            if (isDev === true) {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd != phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                });
            } else {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd.phone && ltcd.phone != phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            }

            tracking = tracking + '0. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_contact_limited) {
            let listBlack = Object.values(customer_contact_limited);
            for (let i = 0; i < listBlack.length; i++) {
                if (listBlack[i].phone) {
                    sorted = sorted.filter(b => b.phone != listBlack[i].phone);
                } else {
                    sorted = sorted.filter(b => b.phone != listBlack[i]);
                }

            }
            tracking = tracking + '\n1. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_signup_via_referral) {
            let driverV = sorted.filter(d => d.phone == '+84' + customer_signup_via_referral || d.phone == customer_signup_via_referral);
            if (driverV) {
                tracking = tracking + '\n2. Nhận số đt tài xế ưu tiên ' + JSON.stringify(driverV);
                if (isDev === true) {
                    return {
                        result: driverV,
                        tracking: tracking,
                        msg: msg
                    }
                } else {
                    return driverV;
                }
            }
        }

        if (settings && settings.approve_by_vip) {
            sorted = sorted.sort(function (a, b) {
                if (!b.perUse) {
                    b.perUse = 0;
                }
                if (!a.perUse) {
                    a.perUse = 0;
                }
                return (parseFloat(b.perUse) - parseFloat(a.perUse));
            });
            tracking = tracking + '\n3. Áp dụng VIP ' + JSON.stringify(sorted);
            let sortedL = sorted.filter(v => v.vip_limit && v.vip_limit > 0);
            // Thông báo xe công nghệ khi hết giới hạn
            // - Bạn đã sử dụng hết quyền ưu tiên hôm nay
            if (sortedL && sortedL.length > 0) {
                sorted = sortedL.sort(function (a, b) {
                    return (parseFloat(b.vip_limit) - parseFloat(a.vip_limit));
                });

                sorted = sorted.filter(v => v.vip_limit == sorted[0].vip_limit);

            }
            tracking = tracking + '\n4. Áp dụng Hạn chế theo ngày ' + JSON.stringify(sorted);

            if (sorted && sorted.length > 0) {
                let sortedV = sorted.filter(vv => vv.driverVipStatus == true);
                tracking = tracking + '\n6. Loại các tài xế ko bật VIP ' + JSON.stringify(sortedV);
                if (sortedV && sortedV.length > 0) {
                    sorted = sortedV;
                    let vipValue = sorted[0].perUse;
                    if (vipValue <= 0) {
                        sorted = sorted.filter(x => x.driverType != 'cong_nghe');
                        // Thông báo xe công nghệ khi ETO = 0
                        // - Bạn cần có điểm ETO để ưu tiên nhận cuốc khách


                        tracking = tracking + '\n5. Vip = 0 loại hết tài xế công nghệ ' + JSON.stringify(sorted);
                    } else {
                        sorted = sorted.filter(v => v.perUse == vipValue);
                        tracking = tracking + '\n5.1. Check  VIP Khả dụng ' + JSON.stringify(sorted);
                        if (sorted && sorted.length > 1) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.vipAt) {
                                    b.vipAt = GetCurrentMiniTimeStamp();
                                }
                                if (!a.vipAt) {
                                    a.vipAt = GetCurrentMiniTimeStamp();
                                }
                                return (parseFloat(a.vipAt) - parseFloat(b.vipAt));
                            });
                            tracking = tracking + '\n5.2. Lấy Vip cũ nhất ' + JSON.stringify(sorted);
                            if (sorted[0] && sorted[1] && sorted[0].vipAt != sorted[1].vipAt) {
                                sorted = [sorted[0]]
                            }
                        }
                    }
                }

            }

            if (sorted && sorted.length > 1) {
                // Wallet
                if (settings && settings.approve_by_wallet) {
                    let minW = settings.min_wallet ? parseFloat(settings.min_wallet) : 100000;

                    let listMinWallet = sorted.filter(lv => !lv.wallet || (lv.wallet && parseFloat(lv.wallet) < minW) || lv.wallet == 0);
                    if (listMinWallet && listMinWallet.length > 0) {
                        for (let i = 0; i < listMinWallet.length; i++) {
                            if (isDev === true) {
                                msg = msg + listMinWallet[i].codeDriver + " Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW + '\n';
                            } else {
                                RequestPushMsg(listMinWallet[i].pushToken, language.notification_title, "Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW);
                            }
                        }
                    }
                    sorted = sorted.filter(lv => lv.wallet && parseFloat(lv.wallet) >= minW);
                    tracking = tracking + '\n7. Loại các tài xế số dư ví ko đủ ' + JSON.stringify(sorted);
                    if (sorted && sorted.length > 1) {
                        //Time
                        if (settings && settings.approve_by_time) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.timestamp) {
                                    b.timestamp = GetCurrentTimeStamp();
                                }
                                if (!a.timestamp) {
                                    a.timestamp = GetCurrentTimeStamp();
                                }
                                return (a.timestamp - b.timestamp);
                            });
                            let timeStampValue = sorted[0].timestamp;
                            sorted = sorted.filter(v => v.timestamp == timeStampValue);
                            tracking = tracking + '\n8. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                            // Speed
                            if (sorted && sorted.length > 1) {
                                sorted = sorted.sort(function (a, b) {
                                    if (!b.time_accept) {
                                        b.time_accept = 0;
                                    }
                                    if (!a.time_accept) {
                                        a.time_accept = 0;
                                    }
                                    return (a.time_accept - b.time_accept);
                                });
                                tracking = tracking + '\n9. Áp dụng Tốc độ ấn ' + JSON.stringify(sorted);
                                if (isDev === true) {
                                    notificationToVipZeroDev(driver_accepted_array, msg);
                                } else {
                                    notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                }

                                if (isDev === true) {
                                    return {
                                        result: sorted,
                                        tracking: tracking,
                                        msg: msg
                                    }
                                } else {
                                    return sorted;
                                }

                            } else {
                                if (isDev === true) {
                                    notificationToVipZeroDev(driver_accepted_array, msg);
                                } else {
                                    if (sorted[0] && sorted[0].uid) {
                                        notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                    }
                                }
                                tracking = tracking + '\n8. Áp dụng Thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                                if (isDev === true) {
                                    return {
                                        result: sorted,
                                        tracking: tracking,
                                        msg: msg
                                    }
                                } else {
                                    return sorted;
                                }
                            }
                        } else {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.time_accept) {
                                    b.time_accept = 0;
                                }
                                if (!a.time_accept) {
                                    a.time_accept = 0;
                                }
                                return (a.time_accept - b.time_accept);
                            });
                            tracking = tracking + '\n8. Áp dụng Thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                notificationToVipZeroDev(driver_accepted_array, msg);
                            } else {
                                if (sorted[0] && sorted[0].uid) {
                                    notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                }
                            }
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        }
                    } else {
                        if (isDev === true) {
                            notificationToVipZeroDev(driver_accepted_array, msg);
                        } else {
                            if (sorted[0] && sorted[0].uid) {
                                notificationToVipZero(driver_accepted_array, sorted[0].uid)
                            }
                        }
                        tracking = tracking + '\n7. Áp dụng số dư ví có 1 lái xe ' + JSON.stringify(sorted);
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                }
            } else {
                if (sorted && sorted.length == 1) {
                    if (sorted[0].perUse == 0) {
                        sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                        if (isDev === true) {
                            notificationToVipZeroDev(driver_accepted_array, msg);
                        } else {
                            if (sorted[0] && sorted[0].uid) {
                                notificationToVipZero(driver_accepted_array, sorted[0].uid)
                            }
                        }
                    }
                }
                tracking = tracking + '\n6. Áp dụng điểm VIP có 1 lái xe ' + JSON.stringify(sorted);
                if (isDev === true) {
                    return {
                        result: sorted,
                        tracking: tracking,
                        msg: msg
                    }
                } else {
                    return sorted;
                }
            }
        } else {
            // Wallet
            if (settings && settings.approve_by_wallet) {
                let minW = settings.min_wallet ? parseFloat(settings.min_wallet) : 100000;
                let listMinWallet = driver_accepted_array.filter(lv => !lv.wallet || (lv.wallet && parseFloat(lv.wallet) < minW) || lv.wallet == 0);
                if (listMinWallet && listMinWallet.length > 0) {
                    for (let i = 0; i < listMinWallet.length; i++) {
                        if (isDev === true) {
                            msg = msg + listMinWallet[i].codeDriver + " Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW + '\n';
                        } else {
                            RequestPushMsg(listMinWallet[i].pushToken, language.notification_title, "Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW);
                        }
                    }
                }
                sorted = driver_accepted_array.filter(lv => lv.wallet && parseFloat(lv.wallet) >= minW);
                tracking = tracking + '\n5. Áp dụng số dư ' + JSON.stringify(sorted);
                sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                tracking = tracking + '\n6. Vip = 0 loại hết tài xế công nghệ ' + JSON.stringify(sorted);
                if (sorted && sorted.length > 1) {
                    //Time
                    if (settings && settings.approve_by_time) {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.timestamp) {
                                b.timestamp = GetCurrentTimeStamp();
                            }
                            if (!a.timestamp) {
                                a.timestamp = GetCurrentTimeStamp();
                            }
                            return (a.timestamp - b.timestamp);
                        });
                        let timeStampValue = sorted[0].timestamp;
                        sorted = sorted.filter(v => v.timestamp == timeStampValue);
                        tracking = tracking + '\n7. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                        // Speed
                        if (sorted && sorted.length > 1) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.time_accept) {
                                    b.time_accept = 0;
                                }
                                if (!a.time_accept) {
                                    a.time_accept = 0;
                                }
                                return (a.time_accept - b.time_accept);
                            });
                            tracking = tracking + '\n8. Áp dụng tốc độ ấn ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        } else {
                            tracking = tracking + '\n7. Áp dụng thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        }
                    } else {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.time_accept) {
                                b.time_accept = 0;
                            }
                            if (!a.time_accept) {
                                a.time_accept = 0;
                            }
                            return (a.time_accept - b.time_accept);
                        });
                        tracking = tracking + '\n7. Áp dụng tốc độ ấn có 1 lx ' + JSON.stringify(sorted);
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                } else {
                    tracking = tracking + '\n6. Áp số dư ví có 1 lx ' + JSON.stringify(sorted);
                    if (isDev === true) {
                        return {
                            result: sorted,
                            tracking: tracking,
                            msg: msg
                        }
                    } else {
                        return sorted;
                    }
                }
            } else {
                // TIME
                if (settings && settings.approve_by_time) {
                    sorted = driver_accepted_array.sort(function (a, b) {
                        if (!b.timestamp) {
                            b.timestamp = GetCurrentTimeStamp();
                        }
                        if (!a.timestamp) {
                            a.timestamp = GetCurrentTimeStamp();
                        }
                        return (a.timestamp - b.timestamp);
                    });
                    tracking = tracking + '\n7. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                    sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                    tracking = tracking + '\n7.1. Loại lx công nghệ ' + JSON.stringify(sorted);
                    let timeStampValue = sorted[0].timestamp;
                    sorted = sorted.filter(v => v.timestamp == timeStampValue);

                    // Speed
                    if (sorted && sorted.length > 1) {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.time_accept) {
                                b.time_accept = 0;
                            }
                            if (!a.time_accept) {
                                a.time_accept = 0;
                            }
                            return (a.time_accept - b.time_accept);
                        });

                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    } else {
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                } else {
                    sorted = driver_accepted_array.sort(function (a, b) {
                        if (!b.time_accept) {
                            b.time_accept = GetCurrentTimeStamp();
                        }
                        if (!a.time_accept) {
                            a.time_accept = GetCurrentTimeStamp();
                        }
                        tracking = tracking + '\n7. Áp dụng tốc độ ấn có 1 lx ' + JSON.stringify(sorted);
                        return (a.time_accept - b.time_accept);
                    });
                    sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                    tracking = tracking + '\n6. Loại lx công nghệ có 1 lx ' + JSON.stringify(sorted);
                    if (isDev === true) {
                        return {
                            result: sorted,
                            tracking: tracking,
                            msg: msg
                        }
                    } else {
                        return sorted;
                    }
                }
            }
        }
    } else {
        if (isDev === true) {
            return {
                result: [],
                tracking: tracking,
                msg: msg
            }
        } else {
            return [];
        }
    }
}


export function checkDriverValidCloud(phoneCustomer, driver_accepted_array, settings = null, customer_signup_via_referral = null, customer_contact_limited = null, isDev = false) {
    let msg = '';
    let tracking = '';
    if (driver_accepted_array && driver_accepted_array.length > 0) {
        var sorted = driver_accepted_array;

        let sortedTemp = sorted.filter(isGroup => isGroup.timestamp !== 9999999999999)
        if (sortedTemp && sortedTemp.length > 1) {
            sorted = sortedTemp;
        }

        if (settings && settings.approve_by_time) {
            sorted = sorted.sort(function (a, b) {
                if (!b.timestamp) {
                    b.timestamp = GetCurrentTimeStamp();
                }
                if (!a.timestamp) {
                    a.timestamp = GetCurrentTimeStamp();
                }
                return (a.timestamp - b.timestamp);
            });
            tracking = tracking + '\n8. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
        }

        if (sorted && sorted.length > 1) {
            if (settings && settings.is_near_customer) {
                let minW = settings.bankinh ? parseFloat(settings.bankinh) : 3;
                sorted = sorted.filter(lv => parseFloat(lv.distanceEstimate) <= minW);
                if (sorted && sorted.length > 1) {
                    sorted = sorted.sort(function (a, b) {
                        if (!b.distanceEstimate) {
                            b.distanceEstimate = 999999;
                        }
                        if (!a.distanceEstimate) {
                            a.distanceEstimate = 999999;
                        }
                        return (a.distanceEstimate - b.distanceEstimate);
                    });
                }

                tracking = tracking + '\n7. Khoảng cách' + JSON.stringify(sorted);
            }
        }
        if (isDev === true) {
            return {
                result: sorted,
                tracking: tracking,
                msg: msg
            }
        } else {
            return sorted;
        }

    } else {
        if (isDev === true) {
            return {
                result: [],
                tracking: tracking,
                msg: msg
            }
        } else {
            return [];
        }
    }
}

export function checkDriverValidV2(phoneCustomer, driver_accepted_array, settings = null, customer_signup_via_referral = null, customer_contact_limited = null, isDev = false) {
    let msg = '';
    let tracking = '';
    if (driver_accepted_array && driver_accepted_array.length > 0) {
        var sorted = driver_accepted_array;
        // VIP
        /*if (phoneCustomer) {
            if (isDev === true) {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd !== phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                });
            } else {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd.phone && ltcd.phone !== phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            }

            tracking = tracking + '0. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_contact_limited) {
            let listBlack = Object.values(customer_contact_limited);
            for (let i = 0; i < listBlack.length; i++) {
                if (listBlack[i].phone) {
                    sorted = sorted.filter(b => b.phone !== listBlack[i].phone);
                } else {
                    sorted = sorted.filter(b => b.phone !== listBlack[i]);
                }

            }
            tracking = tracking + '\n1. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_signup_via_referral) {
            let driverV = sorted.filter(d => d.phone === '+84' + customer_signup_via_referral || d.phone === customer_signup_via_referral);
            if (driverV) {
                tracking = tracking + '\n2. Nhận số đt tài xế ưu tiên ' + JSON.stringify(driverV);
                if (isDev === true) {
                    return {
                        result: driverV,
                        tracking: tracking,
                        msg: msg
                    }
                } else {
                    return driverV;
                }
            }
        }*/

        let sortedTemp = sorted.filter(isGroup => isGroup.timestamp && isGroup.timestamp != 10000000000000000)
        if (sortedTemp && sortedTemp.length > 1) {
            sorted = sortedTemp;
        }

        if (settings && settings.approve_by_time) {
            sorted = sorted.sort(function (a, b) {
                if (!b.timestamp) {
                    b.timestamp = GetCurrentTimeStamp();
                }
                if (!a.timestamp) {
                    a.timestamp = GetCurrentTimeStamp();
                }
                return (a.timestamp - b.timestamp);
            });
            let timeStampValue = sorted[0].timestamp;
            sorted = sorted.filter(v => v.timestamp === timeStampValue);
            tracking = tracking + '\n8. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
            // Speed
        }

        if (sorted && sorted.length > 1) {
            if (settings && settings.is_near_customer) {
                let minW = settings.bankinh ? parseFloat(settings.bankinh) : 3;
                sorted = sorted.filter(lv => lv.distanceEstimate && parseFloat(lv.distanceEstimate) <= minW);
                if (sorted && sorted.length > 1) {
                    sorted = sorted.sort(function (a, b) {
                        if (!b.distanceEstimate) {
                            b.distanceEstimate = 999999;
                        }
                        if (!a.distanceEstimate) {
                            a.distanceEstimate = 999999;
                        }
                        return (a.distanceEstimate - b.distanceEstimate);
                    });
                }

                let distanceTemp = sorted[0].distanceEstimate;
                sorted = sorted.filter(v => v.distanceEstimate === distanceTemp);
                tracking = tracking + '\n7. Khoảng cách' + JSON.stringify(sorted);
            }
        }
        if (sorted && sorted.length > 1) {
            sorted = sorted.sort(function (a, b) {
                if (!b.time_accept) {
                    b.time_accept = 0;
                }
                if (!a.time_accept) {
                    a.time_accept = 0;
                }
                return (a.time_accept - b.time_accept);
            });
            tracking = tracking + '\n6. Tốc độ ấn ' + JSON.stringify(sorted);
        }

        if (isDev === true) {
            return {
                result: sorted,
                tracking: tracking,
                msg: msg
            }
        } else {
            return sorted;
        }

    } else {
        if (isDev === true) {
            return {
                result: [],
                tracking: tracking,
                msg: msg
            }
        } else {
            return [];
        }
    }
}


export function notificationToVipZero(driver_accepted_array, uid) {
    let listDriver = driver_accepted_array.filter(lv => lv.driverVipStatus == true && lv.perUse == 0 && lv.uid != uid);
    if (listDriver && listDriver.length > 0) {
        for (let i = 0; i < listDriver.length; i++) {
            RequestPushMsg(listDriver[i].pushToken, language.notification_title, "Bạn cần có điểm VIP để nhận cuốc khách");
        }
    }
}

export function notificationToVipZeroDev(driver_accepted_array, msg) {
    let listDriver = driver_accepted_array.filter(lv => lv.driverVipStatus == true && lv.perUse == 0);
    if (listDriver && listDriver.length > 0) {
        for (let i = 0; i < listDriver.length; i++) {
            msg = msg + listDriver[i].codeDriver + " ạn cần có điểm VIP để nhận cuốc khách " + '\n';
        }
    }
}

export function prepareDataBeforAddBookingV2(
    bookingData,
    carSelect = null,
    pickUp = null,
    drop = null,
    estimateTemp = null,
    groupKd3Km = null,
    bookingPushOutList = null,
    bookingPushInList = null,
    bookingPushInAreaList = null,
    userByPhone = null
) {
    if (estimateTemp) {
        if (pickUp) {
            estimateTemp.pickup = pickUp;
        }
        if (drop) {
            estimateTemp.drop = drop;
        }
    }
    var data = {
        carType: bookingData.carType ? bookingData.carType : bookingData.carDetails.name,
        carTypeCode: bookingData.carTypeCode ? bookingData.carTypeCode : (carSelect && carSelect.code ? carSelect.code : ''),
        carImage: bookingData.carImage ? bookingData.carImage : (carSelect && carSelect.image ? carSelect.image : ''),
        pickup: pickUp ? pickUp : null,
        pickupFrom: bookingData.pickupFrom ? bookingData.pickupFrom : pickUp ? pickUp : null,
        pickupAddress: pickUp && pickUp.add ? pickUp.add : null,
        drop: drop ? drop : null,
        dropAddress: drop && drop.add ? drop.add : null,
        status: bookingData.bookLater ? "BOOKING_LATER" : "NEWV2",
        bookLater: bookingData.bookLater ? bookingData.bookLater : false,
        otp: false,
        estimateTemp: estimateTemp ? estimateTemp : null,
        tripdate: bookingData.bookingDate ? bookingData.bookingDate : GetCurrentMiniTimeStamp(),
        bookingDate: bookingData.bookingDate ? bookingData.bookingDate : GetCurrentMiniTimeStamp(),
        coords: (pickUp) ? [{
            latitude: pickUp.lat,
            longitude: pickUp.lng
        }] : null,
        bookingDateLaterMin: bookingData.bookingDateLaterMin ? bookingData.bookingDateLaterMin : '',
        driver_group: bookingData.driver_group ? bookingData.driver_group : bookingData.groupDetails ? bookingData.groupDetails : groupKd3Km,
        customer: bookingData.customer ? bookingData.customer : bookingData.userDetails.uid,
        customer_signup_via_referral: userByPhone && userByPhone.signupViaReferral ? userByPhone.signupViaReferral : null,
        customer_name: bookingData.userDetails.profile.firstName + ' ' + bookingData.userDetails.profile.lastName,
        customer_contact_limited: userByPhone && userByPhone.contact_limited ? userByPhone.contact_limited : null,
        signupViaReferral: bookingData.userDetails.profile.signupViaReferral ? bookingData.userDetails.profile.signupViaReferral : '',
        customer_contact: bookingData.customer_contact ? bookingData.customer_contact : bookingData.userDetails.profile.mobile ? bookingData.userDetails.profile.mobile : '',
        customer_note: bookingData.customer_note ? bookingData.customer_note : bookingData.userDetails.profile.note ? bookingData.userDetails.profile.note : '',
        customer_token: bookingData.customer_token ? bookingData.customer_token : bookingData.userDetails.profile.customer_token ? bookingData.userDetails.profile.customer_token : '',
        customer_image: bookingData.customer_image ? bookingData.customer_image : bookingData.userDetails.profile.profile_image ? bookingData.userDetails.profile.profile_image : '',
        driver: null,
        driverRating: null,
        driver_name: null,
        is_auto: 'YES',
        driver_contact: null,
        CodeDriver: null,
        driver_token: null,
        teamId: bookingData.teamId ? bookingData.teamId : null,
        driver_image: null,
        trip_code_status: 'inactive',
        trip_code: generateTripCode(6),
        type: bookingData.type ? bookingData.type : "TT",
        source: bookingData.source ? bookingData.source : "TT",
        callId: bookingData.callId,
        driverBook: bookingData.driverBook ? bookingData.driverBook : null,
        driverBooked: bookingData.driverBook && bookingData.driverBook.code ? bookingData.driverBook.code : null,
        customer_wish: false,
        totalRequest: bookingData.totalRequest ? bookingData.totalRequest : null,
        driverInGroup: bookingData.driverInGroup ? bookingData.driverInGroup : null,
        driverOutGroup: bookingData.driverOutGroup ? bookingData.driverOutGroup : null,
        perCustomer: bookingData.per ? bookingData.per : 0,
        perUseCustomer: bookingData.perUse ? bookingData.perUse : 0,
        driverInArea: bookingData.driverInArea ? bookingData.driverInArea : null,
        bookingPushOutList: bookingPushOutList ? bookingPushOutList : null,
        bookingPushInList: bookingPushInList ? bookingPushInList : null,
        bookingPushInAreaList: bookingPushInAreaList ? bookingPushInAreaList : null,
        is_two_way: bookingData.is_two_way && bookingData.is_two_way === true ? true : false,
        metterTime: 0,
        metterDistance: 0,
        metterPrice: 0,
        estimateDistance: bookingData.priceEstimate ? bookingData.priceEstimate[2] : null,
        estimateTime: bookingData.priceEstimate ? bookingData.priceEstimate[3] : null,
        estimate: bookingData.tripEstimate ? bookingData.tripEstimate : null,
        estimatePrice: bookingData.priceEstimate ? bookingData.priceEstimate : 0,
        tripEstimate: bookingData.tripEstimate ? bookingData.tripEstimate : null,
        areaId: bookingData.areaId ? bookingData.areaId : null,
        prices: bookingData.prices ? bookingData.prices : null
    }

    if (data.driver_group && data.driver_group.prices && data.carTypeCode) {
        data.prices = data.driver_group.prices[data.carTypeCode];
    }

    return data;
}
