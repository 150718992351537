import React, {useCallback, useEffect, useRef, useState} from 'react';
import Map, {Layer, Marker, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useDispatch, useSelector} from "react-redux";
import store from "common/src/store/store";
import {api} from "common";
import {FareCalculator2} from "common/src/other/FareCalculator";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../config/theme";
import {language} from "config";
import {findSurroundingNumbers} from "common/src/other/CommonFunctions";
import {fetchAddressfromCoords, getDriveTime} from "common/src/other/MapboxAPIFunctions";
import {GetDistance} from "common/src/other/GeoFunctions";

const useStyles = makeStyles(theme => ({
    labelMarkerQueue: {
        marginTop: -28,
        backgroundColor: colors.BLUE.secondary,
        paddingLeft: 3,
        paddingRight: 3
    },
    labelMarkerActive: {
        marginTop: -28,
        backgroundColor: colors.GREY.secondary,
        paddingLeft: 3,
        paddingRight: 3
    },
    labelMarkerInActive: {
        marginTop: -28,
        backgroundColor: colors.RED,
        paddingLeft: 3,
        paddingRight: 3
    },

}));

const layerStyleEto = {
    id: "tileset-layer",
    type: "fill",
    source: "my-tileset",
    "source-layer": "Lp_khng_c_tiu_", // Quan trọng!
    paint: {
        "fill-color": "#0080ff",
        "fill-opacity": 0.5,
    },
};

const image4Urls = [
    '4x0.png',
    '4x30.png',
    '4x60.png',
    '4x90.png',
    '4x120.png',
    '4x150.png',
    '4x180.png',
    '4x210.png',
    '4x240.png',
    '4x270.png',
    '4x300.png',
    '4x330.png',
];

const image5Urls = [
    '5x0.png',
    '5x30.png',
    '5x60.png',
    '5x90.png',
    '5x120.png',
    '5x150.png',
    '5x180.png',
    '5x210.png',
    '5x240.png',
    '5x270.png',
    '5x300.png',
    '5x330.png',
];

const image7Urls = [
    '7x0.png',
    '7x30.png',
    '7x60.png',
    '7x90.png',
    '7x120.png',
    '7x150.png',
    '7x180.png',
    '7x210.png',
    '7x240.png',
    '7x270.png',
    '7x300.png',
    '7x330.png',
];

const listCar = {
    taxi: image4Urls,
    sedan: image5Urls,
    suv: image7Urls
}

export default function MapBox(props) {
    const {
        userDetail,
        bookingDetail,
        updateTripPickup,
        updateTripDrop
    } = api;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [viewport, setViewport] = useState(null);

    const layerStyle = {
        id: 'polygon-layer',
        type: 'fill',
        paint: {
            'fill-color': '#088',
            'fill-opacity': 0.5,
        },
    };
    const routeLayer = {
        id: "route",
        type: "line",
        source: "route",
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": "#FF5733", // Màu của tuyến đường
            "line-width": 4,        // Độ dày tuyến đường
        },
    };

    const [markers, setMarkers] = useState([]);
    const [hideCircle, setHideCircle] = useState(false);
    const [carInfo, setCarInfo] = useState(null);
    const auth = useSelector(state => state.auth);
    const bookingdata = useSelector(state => state.bookingdata);
    const [markerSelected, setMarkerSelected] = useState(null);
    const [searchAddress, setSearchAddress] = useState(null);
    const [callCenterDetail, setCallCenterDetail] = useState(null);
    const [bookingObj, setBookingObj] = useState(null);
    const [booking, setBooking] = useState(null);
    const [driverActive, setDriverActive] = useState(null);
    const mapRef = useRef();
    const tripdata = useSelector(state => state.tripdata);
    const [zoom, setZoom] = useState(12);
    const mapType = useSelector(state => state.sessiondata.mapType);
    let cars = store.getState().cartypes.cars;
    const settings = useSelector((state) => state.settingsdata.settings);
    const webMapZoom = useSelector(state => state.sessiondata.webMapZoom);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    const [datasetFeatures, setDatasetFeatures] = useState([]);
    const onMapClick = useCallback((event) => {
        const {lng, lat} = event.lngLat;
        /*
                // Thêm một marker mới vào danh sách
                setMarkers((prevMarkers) => [
                    ...prevMarkers,
                    {longitude: lng, latitude: lat},
                ]);*/


        if (props.isPickup && tripdata.selected == 'drop') {
            dispatch(updateTripDrop({
                lat: lat,
                lng: lng,
                add: "Chưa xác định",
                from: "map"
            }));
        }

        if (props.isPickup && tripdata.selected == 'pickup') {
            dispatch(updateTripPickup({
                lat: lat,
                lng: lng,
                add: "Chưa xác định",
                from: "map"
            }));
        }

        if (props.isPickup) {
        } else {

        }

    }, [tripdata.selected]);
    const [directions, setDirections] = useState(null);
    const [wayPoints, setWayPoints] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [moveMap, setMoveMap] = useState(false);
    // Function to handle zoom changes
    var delayFactor = 0;

    const [markerIconP, setMarkerIconP] = useState(require('../assets/img/ico_start_13xd.png')); // Default icon


    useEffect(() => {
    }, []);


    useEffect(() => {
        if (!moveMap) {
            if (props.zoom) {
                setZoom(props.zoom);
            }
            if (props.center) {
                let viewTemp = props.center;
                if (props.zoom) {
                    viewTemp.zoom = props.zoom;
                }
                setViewport(viewTemp);
            }
        }

    }, [props.center, props.zoom]);
    useEffect(() => {
        let markerList = [];
        if (!moveMap) {
            if (props.isPickup && tripdata.pickup && tripdata.pickup.lat) {
                if (tripdata.selected == 'pickup') {
                    setViewport({
                        latitude: tripdata.pickup.lat, // Vĩ độ (latitude)
                        longitude: tripdata.pickup.lng, // Kinh độ (longitude)
                        zoom: 12, // Độ zoom
                    });
                }
                setZoom(17);

                markerList[0] = {
                    lat: tripdata.pickup.lat,
                    lng: tripdata.pickup.lng,
                    time: new Date(),
                    type: 'pickup',
                    //address_string: tripdata.pickup.add,
                    icon: {
                        url: markerIconP,
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }
            }

            if (props.isPickup && tripdata.drop && tripdata.drop.lat) {
                if (tripdata.selected == 'drop') {
                    setViewport({
                        latitude: tripdata.drop.lat, // Vĩ độ (latitude)
                        longitude: tripdata.drop.lng, // Kinh độ (longitude)
                        zoom: 12, // Độ zoom
                    });
                }
                markerList[1] = {
                    lat: tripdata.drop.lat,
                    lng: tripdata.drop.lng,
                    time: new Date(),
                    type: 'drop',
                    //address_string: tripdata.drop.add,
                    icon: {
                        url: (require('../assets/img/ico_stop_23x.png')),
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }

            }
            setMarkers(markerList);

            if (tripdata && tripdata.carType) {
            } else {
                let listCar = cars ? cars.filter(c => c.type == 'taxi') : []
                dispatch(api.updateTripCar(listCar.filter(c => c.code == 'taxi')[0]));
            }
            var startLoc = null;
            var destLoc = null;

            if (tripdata.pickup && tripdata.drop && tripdata.price) {
                let prices = tripdata.price;
                let startLoc1 = '20.945759,106.740893';
                let destLoc1 = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
                startLoc = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
                destLoc = tripdata.drop.lat + ',' + tripdata.drop.lng;
                getDriveTime(startLoc.split(",")[0], startLoc.split(",")[1], destLoc.split(",")[0], destLoc.split(",")[1], settings.mb_token_pk).then((arriveTime2) => {
                    if (arriveTime2 && arriveTime2.distance_in_km) {
                        if (prices) {
                            let fareCalculation = FareCalculator2(arriveTime2.distance_in_km, parseFloat(arriveTime2.time_in_secs), prices, 0, 0, prices, true);
                            fareCalculation.push(arriveTime2.distance_in_km);
                            fareCalculation.push(parseFloat(arriveTime2.time_in_secs));
                            console.log('fareCalculation', fareCalculation);
                            dispatch(api.updateEstimateTemp(fareCalculation));
                        }
                    } else {
                        var distanceEstimate2 = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, tripdata.drop.lat, tripdata.drop.lng);
                        var timeEstimate2 = (distanceEstimate2 / 35) * 60;
                        if (prices) {
                            let fareCalculation = FareCalculator2(distanceEstimate2, parseFloat(timeEstimate2), prices, 0, 0, prices, true);
                            fareCalculation.push(distanceEstimate2);
                            fareCalculation.push(parseFloat(timeEstimate2));
                            dispatch(api.updateEstimateTemp(fareCalculation));
                        }
                    }
                }).catch((error) => {
                    console.log("Error", error);
                });
            } else {
                dispatch(api.updateEstimateTemp(null));
            }

        }
    }, [tripdata.carType, tripdata.drop, tripdata.pickup, tripdata.selected, tripdata.price]);

    useEffect(() => {
        if (props.callDetail) {
            setCallCenterDetail(props.callDetail);
            if (props.callDetail.accept_driver) {
                dispatch(userDetail(props.callDetail.accept_driver));
            }
            if (props.callDetail.booking_id) {
                dispatch(bookingDetail(props.callDetail.booking_id));
            }
        }
    }, [props.callDetail]);


    useEffect(() => {
        if (props.bookingObject) {
            setBookingObj(props.bookingObject);
        }
    }, [props.bookingObject]);

    useEffect(() => {
        if (auth.user) {
            setDriverActive(auth.user);
        }
    }, [auth]);

    const loadCarInfo = useCallback((driver) => {
        if (driver) {
            setCarInfo(driver);
            setViewport({
                latitude: driver.location.lat, // Vĩ độ (latitude)
                longitude: driver.location.lng, // Kinh độ (longitude)
                zoom: 12, // Độ zoom
            });
        } else {
            setCarInfo(null);
        }
    }, []);

    useEffect(() => {
        if (driverActive && driverActive.location) {
            setViewport({
                latitude: driverActive.location.lat, // Vĩ độ (latitude)
                longitude: driverActive.location.lng, // Kinh độ (longitude)
                zoom: 12, // Độ zoom
            });
        }
    }, [driverActive]);

    const onMarkerDragEnd = useCallback((e, markerPick, index) => {
        markerPick.lat = e.latLng.lat();
        markerPick.lng = e.latLng.lng();
        markerPick.time = new Date();
        markers[index] = markerPick;
        setMarkers(markers);
    }, []);

    const closeCallback = useCallback(
        () => {
            setDriverActive(null);
            setBooking(null);
            setBookingObj(null);
        },
        [],
    );

    return (
        <div style={{height: '100vh', width: '100%'}}>
            <Map
                key={"mapbox_eto"}
                initialViewState={viewport}
                viewState={viewport}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                mapboxAccessToken={"pk.eyJ1IjoidGt2Y2x1YjAxaHAiLCJhIjoiY202MXp6cXhjMDhhcDJrbjJxc3d2NTh0aSJ9.cc3oyxbgLnRZSr_M_5s_Cg"}
                zoom={zoom}
                style={{width: '100%', height: '100%'}}
                onDragStart={() => {
                    setMoveMap(true);
                }
                }
                onDrag={(evt) => {
                    setViewport(evt.viewState);
                }
                }
                onDragEnd={() => {
                    setMoveMap(false);
                }
                }
                onZoom={(evt) => {
                    setViewport(evt.viewState);
                }
                }
                /*onMove={(evt) => {
                    setViewport(evt.viewState);
                }
                }
                onMoveEnd={() => {
                    setMoveMap(false);
                }
                }*/
            >
                <Source id="my-tileset" type="vector" url={"mapbox://tkvclub01hp.9r1apf26"}>
                    <Layer
                        id="tileset-layer"
                        type="fill"
                        source="my-tileset"
                        source-layer={"Lp_khng_c_tiu_"} // Quan trọng
                        paint={{
                            "fill-color": "blue",
                            "fill-opacity": 0.5,
                        }}
                    />
                </Source>

                {props.isPickup && props.coords && props.coords.length > 0 ?
                    <Source id="polygon" type="geojson" data={props.coords.filter(c => c.type == "metter")}>
                        <Layer {...layerStyle} />
                    </Source>
                    : null
                }

                {/*{datasetFeatures.length > 0 && viewport && viewport.zoom && viewport.zoom > 15 ? datasetFeatures.map((feature, index) => {
                    return (
                        <>
                            <React.Fragment
                                key={feature.id}
                            >
                                <Marker
                                    key={'point' + feature.id}
                                    latitude={feature.geometry.coordinates[1]}
                                    longitude={feature.geometry.coordinates[0]}
                                    color={colors.BLUE.sky}
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            setViewport({
                                                latitude: feature.geometry.coordinates[1], // Vĩ độ (latitude)
                                                longitude: feature.geometry.coordinates[0], // Kinh độ (longitude)
                                                zoom: 17, // Độ zoom
                                            });
                                        }
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            background: "rgba(255,255,255,0.51)",
                                            padding: "2px 5px",
                                            color: colors.BLUE.secondary,
                                            borderRadius: "5px",
                                            fontSize: "11px",
                                        }}
                                    >
                                        {feature.properties.title}
                                    </div>
                                </Marker>
                            </React.Fragment>
                        </>
                    );
                }) : null}*/}

                {markers ? markers.map((markerPick, index) => {
                    return (
                        <>
                            <React.Fragment
                                key={markerPick.lat + markerPick.lng}
                            >
                                <Marker
                                    latitude={markerPick.lat}
                                    longitude={markerPick.lng}
                                    color="red"
                                    onClick={() => {
                                        if (props.isPickup) {
                                            if (markerPick.type && markerPick.type == 'pickup') {
                                                dispatch(api.updatSelPointType('pickup'));
                                            }
                                            if (markerPick.type && markerPick.type == 'drop') {
                                                dispatch(api.updatSelPointType('drop'));
                                            }
                                        } else {
                                            loadCarInfo(null);
                                            setMarkerSelected(markerPick);
                                            setHideCircle(!hideCircle);
                                        }
                                    }}
                                    draggable={tripdata && (tripdata.isEditPickup && tripdata.selected == 'pickup' && markerPick.type == 'pickup' || tripdata.isEditDrop && tripdata.selected == 'drop' && markerPick.type == 'drop') ? true : false}
                                    onDragEnd={async (e) => {
                                        const {lngLat} = e;
                                        let add = await fetchAddressfromCoords(lngLat.lat, lngLat.lng, settings.mb_token_pk);
                                        console.log("lngLat", lngLat);
                                        if (tripdata.selected == 'pickup') {
                                            console.log("lngLat 1", lngLat);
                                            let pickupUpdate = tripdata.pickup;
                                            pickupUpdate.lat = lngLat.lat;
                                            pickupUpdate.lng = lngLat.lng;
                                            pickupUpdate.add = add;
                                            pickupUpdate.shortAdd = add ? add.split(",")[0] : add;
                                            dispatch(updateTripPickup(pickupUpdate));
                                            props.getPickupCallback(pickupUpdate);
                                        } else {
                                            console.log("lngLat", lngLat);
                                            let dropUpdate = tripdata.drop;
                                            dropUpdate.lat = lngLat.lat;
                                            dropUpdate.lng = lngLat.lng;
                                            dropUpdate.add = add;
                                            dropUpdate.shortAdd = add ? add.split(",")[0] : add;
                                            dispatch(updateTripDrop(dropUpdate));
                                        }

                                        let selectMk = {
                                            lat: lngLat.lat,
                                            lng: lngLat.lng,
                                            type: markerPick.type ? markerPick.type : null,
                                            time: new Date(),
                                            address: add,
                                            address_string: add,
                                            circle: {
                                                radius: 3000,
                                                options: {
                                                    strokeColor: "#ff0000"
                                                }
                                            }
                                        };

                                        markers[index] = selectMk;
                                        setMarkers(markers);
                                        setMarkerSelected(selectMk);
                                    }}
                                    title={language.click_to_book}
                                />
                            </React.Fragment>
                        </>
                    );
                }) : null}


                {props.rows ? props.rows.map((row, index) => {
                    let image = '4x0.png';
                    if (row.location && row.location.heading) {
                        let images = listCar[row.carTypeCode];
                        let resultHeading = findSurroundingNumbers([0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330], row.location.heading);
                        let nameImage = images[0];//x7_270_288x
                        let image0 = nameImage.split("x")[0];
                        image = image0 + "x" + resultHeading.toString() + ".png";
                    }

                    let icon = {
                        url: (require('../assets/img/' + image)),
                        //scaledSize: new window.google.maps.Size(30, 30),
                        anchor: {x: 6, y: 15},
                        rotation: 45
                    }

                    if (row.location && row.location.lat && row.driverActiveStatus) {
                        return (
                            <>
                                <React.Fragment
                                    key={row.lat + row.lng}
                                >
                                    <Marker
                                        latitude={row.location.lat}
                                        longitude={row.location.lng}
                                        key={row.codeDriver.toString()}
                                        onClick={async () => {

                                        }}
                                        label={
                                            {
                                                color: colors.WHITE,
                                                fontWeight: 'bold',
                                                fontSize: '12px',
                                                text: row.codeDriver,
                                                className: row.queue ? classes.labelMarkerQueue : row.driverActiveStatus ? classes.labelMarkerActive : classes.labelMarkerInActive
                                            }
                                        }
                                    >
                                        <div
                                            style={{
                                                marginTop: "5px",
                                                background: row.queue ? colors.BLUE.secondary : "white",
                                                padding: "2px 5px",
                                                color: colors.BACKGROUND_DARK,
                                                borderRadius: "5px",
                                                fontSize: "12px",
                                                fontWeight: 'bold',
                                                boxShadow: "0px 1px 3px rgba(0,0,0,0.3)",
                                            }}
                                        >
                                            {row.codeDriver.toString()}
                                        </div>
                                        <img
                                            src={icon.url} // Đường dẫn đến hình ảnh
                                            alt="marker"
                                            style={{width: '30px', height: '30px', cursor: 'pointer'}}
                                        />
                                    </Marker>
                                </React.Fragment>
                            </>
                        );
                    }

                }) : null}

            </Map>
        </div>
    );
}