import {cloud_function_server_url_asian, Google_Map_Key, Google_Map_Key_Web} from 'config';
import {
    cloud_function_server_url
} from 'config';

export const fetchCoordsfromPlace = async (place_id) => {
    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + place_id + '&key=' + Google_Map_Key);
    const json = await response.json();
    if (json.results && json.results.length > 0 && json.results[0].geometry) {
        let coords = json.results[0].geometry.location;
        return coords;
    }
    return null;
}

export const fetchDirections = async (origin, destination) => {
    let response = await fetch(`${cloud_function_server_url_asian}/get_directions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "start": origin,
            "dest": destination,
            "google_map_key": Google_Map_Key
        })
    });
    let json = await response.json();
    return json;
}


export const getAddressDefaultAutoCompleted = async (searchText, mb_token) => {
    try {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                searchText
            )}.json?access_token=${mb_token}&language=vi`
        );
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            /*const { center, place_name } = data.features[0];
            setMarkerPosition({
                longitude: center[0],
                latitude: center[1],
                place_name,
            });*/
            return data.features;
        } else {
            return [];
            alert('Không tìm thấy địa chỉ!');
        }
    } catch (error) {
        return [];
        console.error('Lỗi khi tìm kiếm:', error);
        alert('Có lỗi xảy ra khi tìm kiếm.');
    }
}


export const fetchAddressfromCoords = (latitude, longitude, mb_token) => {
    return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mb_token}`, {
        method: 'GET',
    })
        .then(response => response.text())
        .then(result => {
            let data = JSON.parse(result);

            // Lấy địa chỉ từ kết quả đầu tiên
            const address = data.features[0]?.place_name || "Address not found";
            console.log("Address:", address);
            return address;
        })
        .catch(error => console.log('error', error));
    return null;
}

export const fetchAddressFullfromCoords = (latlng) => {
    return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng + '&key=' + Google_Map_Key, {
        method: 'GET',
    })
        .then(response => response.text())
        .then(result => {
            let data = JSON.parse(result);
            if (data.results && data.results.length > 0 && data.results[0].formatted_address) {
                return data.results[0];
            }
        })
        .catch(error => console.log('error', error));
    return null;
}

export const getRouteDetails = async (startLoc, destLoc) => {
    let response = await fetch(`${cloud_function_server_url_asian}/get_route_details`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "start": startLoc,
            "dest": destLoc,
            "google_map_key": Google_Map_Key
        })
    });
    let json = await response.json();
    if (json.distance) {
        return json;
    } else {
        console.log(json.error);
    }
    return null;
}

export const getDriveTime = async (startLat, startLng, endLat, endLng, accessToken) => {
    if (!startLng || !startLat || !endLng || !endLat) {
        return null
    } else {

        let response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${startLng},${startLat};${endLng},${endLat}?geometries=geojson&language=vi&access_token=${accessToken}`, {
            method: 'GET'
        });
        console.log("response", response);
        const json = await response.json();
        console.log("json", json);
        if (json) {
            const route = json.routes[0];
            const coordinates = json.routes[0].geometry;

            // Chuyển đổi từ [lng, lat] sang [lat, lng] để phù hợp với Leaflet
            const latLngCoordinates = coordinates;
            return {
                distance_in_km: route.distance / 1000,
                time_in_secs: route.duration,
                time_in_text: route.duration,
                coordinates: latLngCoordinates
            }
        } else {
            return null;
        }
    }
}


export const getSetting = async (key = 'eto675675') => {
    let response = await fetch(`${cloud_function_server_url_asian}/getSetting?key=${key}`, {
        method: 'GET'
    });
    const json = await response.json();
    if (json) {
        return json;
    } else {
        return '';
    }
}