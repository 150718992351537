import {
    UPDATE_TRIP_PICKUP,
    UPDATE_TRIP_DROP,
    UPDATE_TRIP_CAR,
    UPDATE_SELECTED_POINT_TYPE,
    CLEAR_TRIP_POINTS, UPDATE_BOOKING_TIME, UPDATE_TWO_WAY, UPDATE_TRIP_NOTE, UPDATE_TRIP_PHONE, UPDATE_TRIP_CALLCENTER, UPDATE_TRIP_GROUP, UPDATE_TRIP_PRICE, UPDATE_BOOKING_ESTIMATE, UPDATE_TRIP_AREA, UPDATE_ADD_DROP, UPDATE_TRIP_AUTO, UPDATE_BOOKING_ESTIMATE_TEMP, IS_EDIT_PICKUP, IS_EDIT_DROP, UPDATE_TRIP_WAYS
} from "../store/types";

const INITIAL_STATE = {
    pickup: null,
    drop: null,
    carType: null,
    selected: 'pickup',
    booking_time: null,
    is_two_way: false,
    phone: null,
    note: null,
    callCenterObj: null,
    groupApply: null,
    price: null,
    trip_estimate: null,
    trip_estimate_temp: null,
    area: null,
    isAddDrop: false,
    isEditPickup: false,
    isEditDrop: false,
    isKq: false,
    ways: []
}

export const tripreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_TRIP_WAYS:
            return {
                ...state,
                ways: action.payload
            };
        case UPDATE_TRIP_PICKUP:
            return {
                ...state,
                pickup: action.payload
            };
        case UPDATE_ADD_DROP:
            return {
                ...state,
                isAddDrop: action.payload
            };
        case UPDATE_TRIP_DROP:
            return {
                ...state,
                drop: action.payload
            };
        case UPDATE_TRIP_CAR:
            return {
                ...state,
                carType: action.payload
            };
        case UPDATE_SELECTED_POINT_TYPE:
            return {
                ...state,
                selected: action.payload
            };
        case UPDATE_BOOKING_TIME:
            return {
                ...state,
                booking_time: action.payload
            };
        case UPDATE_BOOKING_ESTIMATE:
            return {
                ...state,
                trip_estimate: action.payload
            };
        case UPDATE_BOOKING_ESTIMATE_TEMP:
            return {
                ...state,
                trip_estimate_temp: action.payload
            };
        case UPDATE_TWO_WAY:
            return {
                ...state,
                is_two_way: action.payload
            };
        case UPDATE_TRIP_NOTE:
            return {
                ...state,
                note: action.payload
            };
        case UPDATE_TRIP_PHONE:
            return {
                ...state,
                phone: action.payload
            };
        case UPDATE_TRIP_CALLCENTER:
            return {
                ...state,
                callCenterObj: action.payload
            };
        case UPDATE_TRIP_GROUP:
            return {
                ...state,
                groupApply: action.payload
            };
        case UPDATE_TRIP_PRICE:
            return {
                ...state,
                price: action.payload
            };
        case UPDATE_TRIP_AREA:
            return {
                ...state,
                area: action.payload
            };
        case UPDATE_TRIP_AUTO:
            return {
                ...state,
                isKq: action.payload
            };
        case IS_EDIT_PICKUP:
            return {
                ...state,
                isEditPickup: action.payload
            };
        case IS_EDIT_DROP:
            return {
                ...state,
                isEditDrop: action.payload
            };
        case CLEAR_TRIP_POINTS:
            return INITIAL_STATE;
        default:
            return state;
    }
};