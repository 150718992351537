export const MainConfig = {
    AppDetails: {
        app_name: "ETO",
        app_description: "ETO - Ứng dụng gọi xe chuyên nghiệp",
        app_identifier: "com.etoviet3.taxi",
        ios_app_version: "5.1.0",
        android_app_version: 510
    },
    chatgpt: {
        api_key: "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",
        base_url: "https://api.openai.com/v1",
        org_key: "org-L1iUmM7J0YQbsadMX4jupoo7",
        post_method: {
            chat_completions: "https://api.openai.com/v1/chat/completions"
        }

    },
    domain: "https://eto.net.vn",
    PA_API_CALLNOW: {
        api_key: "4c45e7655ec7fcb3389ec2a9cb9fbe92",
        url: "https://crm.pavietnam.vn/api/callNow.php"
    },
    VIETQR: {
        clientID: "05b64521-a8aa-4f7c-83fc-869e7dad2230",
        apiKey: "bfebe43d-0562-4a05-adc1-f316fd31f88a"
    },
    zaloConfig: {
        login_callback: "login/zalo",
        app_id: "281230242200426903",
        oa_id: "3668069540900918550",
        app_secret: "9kB55DBmGUBv4BQ48TDo",
        sdk_version: "v4",
        sdk_url: "https://oauth.zaloapp.com",
        icon: "https://page.widget.zalo.me/static/images/2.0/Logo.svg"
    },
    FirebaseConfig: {
        apiKey: "AIzaSyC3LIL9viOLwUPnsUgk0SfYaukg2UTMamE",
        authDomain: "eto-dev-3.firebaseapp.com",
        databaseURL: "https://eto-dev-3-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "eto-dev-3",
        storageBucket: "eto-dev-3.firebasestorage.app",
        messagingSenderId: "210990181093",
        appId: "1:210990181093:web:f3cea1e2ccab72b7c6c20c"
    },
    Google_Map_Key: "AIzaSyDyyf31s5SnyXlXrVQw7GFtsZN5xnPiM2c",
    Google_Map_Key_Web: "AIzaSyDyyf31s5SnyXlXrVQw7GFtsZN5xnPiM2c",
    iosClientId: "314152728806-kt5410q75ue7d3fnhnefrk1hqc9inko5.apps.googleusercontent.com",
    androidClientId: "314152728806-cmh728bv04jdad8dag7n1mko4g0oc4gg.apps.googleusercontent.com",
    iosStandaloneAppClientId: "1:314152728806:ios:efffc664413951b0e16327",
    androidStandaloneAppClientId: "1:656348424055:android:097ef1b4f77a33e7582372",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "71940451-60a5-4743-ab02-52c118f28b70",
        Buyer_Email_Address: "tkvclub01@hotmail.com"
    },
    CATCHA_SITE_KEY: "6LfGb7kqAAAAAAYcbaVDQVM5L5HNSwS3oQc2HcTv",
    CATCHA_SECRET_KEY: "6LfGb7kqAAAAAJ5pcw8BHYqStg_PKaQOVwBkWVyA",
    Twilio: {
        TWILIO_AUTH_TOKEN: "0fea99c047ce8d90d2113830b2d26357",
        TWILIO_AUTH_SID: "AC3da16921dae0384caf45e7c13e10606a"
    },
    expo_owner: "etolocal",
    expo_slug: "eto",
    expo_project_id: "7f11e52f-9eb6-4bfc-bec9-c22563c439f6"
}