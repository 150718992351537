import {MainConfig} from './mainconfig';
import {features} from './features';

import {
    language,
    timeZone,
    dateStyle
} from './language';

import {
    countries,
    default_country_code
} from './countries';

const AppDetails = MainConfig.AppDetails;
const PA_API_CALLNOW = MainConfig.PA_API_CALLNOW;
const FirebaseConfig = MainConfig.FirebaseConfig;
const Google_Map_Key = MainConfig.Google_Map_Key;
const Google_Map_Key_Web = MainConfig.Google_Map_Key_Web;
const CAPTCHA_SITE_KEY = MainConfig.CATCHA_SITE_KEY;
const PurchaseDetails = MainConfig.PurchaseDetails;
const ChatGptApiConfig = MainConfig.chatgpt;
const mainUrl = "cloudfunctions.net";
const cloud_function_server_url = `https://us-central1-${FirebaseConfig.projectId}.${mainUrl}`;
const cloud_function_server_url_asian = `https://us-central1-${FirebaseConfig.projectId}.${mainUrl}`;
export {
    CAPTCHA_SITE_KEY,
    AppDetails,
    PA_API_CALLNOW,
    language,
    FirebaseConfig,
    Google_Map_Key,
    Google_Map_Key_Web,
    PurchaseDetails,
    dateStyle,
    cloud_function_server_url,
    cloud_function_server_url_asian,
    features,
    countries,
    default_country_code,
    timeZone,
    mainUrl,
    ChatGptApiConfig
}
