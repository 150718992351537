import React, {useContext, useEffect, useState} from 'react';
import {api, FirebaseContext} from "common";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {CALL_DETAIL_DATA, UPDATE_ZOOM_MAP} from "common/src/store/types";
import {GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import fetch from "node-fetch";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    inputDark: {
        color: "#FFFFFF"
    },
    inputLight: {
        backgroundColor: '#e1e1e1',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
    }
}));

export default function NoteCallCenterComponent(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {refApi} = useContext(FirebaseContext);
    const {updateCallCenterApi} = refApi;
    const [note, setNote] = useState(null);
    const [noteString, setNoteString] = useState(null);
    const [filterOptions, setFilterOptions] = useState([]);

    async function addFeatureToDataset(feature, datasetUrl) {
        const response = await fetch(datasetUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({features: [feature]}), // Chỉ thêm một feature vào mỗi lần gọi API
        });

        if (response.ok) {
            console.log(`Feature added successfully`);
        } else {
            console.log(`Failed to add feature: ${response.statusText}`);
        }
    }

    useEffect(() => {
        if (props.arrayOptions && noteString) {
            const filteredOptions = props.arrayOptions.filter(async (option) => {
                    if (option.note && option.note.toLowerCase().includes(noteString.toLowerCase())) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
            setFilterOptions(filteredOptions)
        }
    }, [props.arrayOptions, noteString]);

    /*useEffect(() => {
        if (!noteString) {
            let string = note ? note.note : props.rowData.booking_note ? props.rowData.booking_note : (props.rowData.note ? props.rowData.note : "");
            setNoteString(string);
        }
    }, [props.rowData]);*/
    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return (
        <Autocomplete
            freeSolo
            onChange={async (event, value) => {

                /*if (props.arrayOptions) {
                    const username = "tkvclub01hp"; // Replace with your Mapbox username
                    const accessToken = "sk.eyJ1IjoidGt2Y2x1YjAxaHAiLCJhIjoiY202NmIwaWo2MWtmMTJsc2VyaTczenNnbSJ9.Bqj8m0TmpNeXVekSMHlN4g"; // Replace with your Access Token
                    const datasetId = "cm6da3wxt976r1no7ap34fsp6";

                    // Get Data From ETO
                    console.log("start adding feature:");
                    for (const data of props.arrayOptions) {
                        if (data.lng && data.lat && data.note) {
                            let fid = GetCurrentTimeStamp();
                            const datasetUrl = `https://api.mapbox.com/datasets/v1/${username}/${datasetId}/features/${fid}?access_token=${accessToken}`;
                            await delay(3000);
                            let feature = {
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: [data.lng, data.lat], // Longitude, Latitude
                                },
                                properties: {
                                    name: data.note,
                                    title: data.note
                                },
                            };
                            console.log("adding feature:", data.note);
                            try {
                                const response = await axios.put(datasetUrl, feature, {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                });
                                console.log("Feature added successfully:", response.data);
                            } catch (error) {
                                console.log("Error adding feature:", error);
                            }
                            await delay(4000);
                        }

                    }
                }*/

                if (props.rowData && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                    if (value && value.note && value.coords) {
                        setNote(value);
                        let data = {
                            add: value.add ? value.add : value.note,
                            shortAdd: value.shortAdd ? value.shortAdd : value.note,
                            lat: value.coords ? parseFloat(value.coords.split(',')[0]) : '',
                            lng: value.coords ? parseFloat(value.coords.split(',')[1]) : ''
                        }
                        dispatch(api.updateTripNote(value.note));

                        if (props.rowData.id) {
                            updateCallCenterApi(props.rowData.id, {
                                note: value.note,
                                pickup: data
                            });
                        }

                        let updateCallSession = {...props.rowData};
                        updateCallSession.note = value.note;
                        updateCallSession.pickup = data;
                        dispatch({
                            type: CALL_DETAIL_DATA, payload: updateCallSession,
                        });
                        if (props.rowData.id) {
                            dispatch(api.updateCallCancelById(props.rowData.id, updateCallSession));
                        }

                        dispatch(api.updateTripPickup(data));
                        dispatch({
                            type: UPDATE_ZOOM_MAP, payload: 16,
                        });
                    } else {
                        /*updateCallCenterApi(props.rowData.id, {
                            note: '',
                            pickup: null
                        });
                        let updateCallSession = {...props.rowData};
                        updateCallSession.note = '';
                        updateCallSession.pickup = null;
                        dispatch({
                            type: CALL_DETAIL_DATA, payload: updateCallSession,
                        });*/
                    }

                }
                setNote(value);
                // eslint-disable-next-line no-unused-expressions
                props.closeEditNote ? props.closeEditNote() : null;
            }
            }
            id={"note_autocompleted" + props.rowData.uniqueid}
            options={filterOptions ? filterOptions : props.arrayOptions}
            getOptionLabel={option => option.note ? option.note : ''}
            /*isOptionEqualToValue={(option, value) => {
                if (option) {
                    console.log("option", option);
                }
                if (value) {
                    console.log('value', value);
                }
                return option.note === value;
            }
            }*/
            value={note && note.note ? note : props.rowData.note ? props.rowData : ""}
            renderInput={(params) =>
                <TextField
                    {...params}
                    id={"text_" + props.rowData.uniqueid}
                    fullWidth
                    disabled={props.isApp}
                    /*InputProps={{
                        disableUnderline: true,
                        className: classes.inputLight
                    }}*/
                    placeholder={"Chưa có"}
                    onChange={(event) => {
                        setNoteString(event.target.value);
                        dispatch(api.updateTripNote(event.target.value));

                        /* let data = {
                             add: value.add ? value.add : value.note,
                             shortAdd: value.add ? value.add : value.note,
                             lat: value.coords ? parseFloat(value.coords.split(',')[0]) : '',
                             lng: value.coords ? parseFloat(value.coords.split(',')[1]) : ''
                         }*/

                        updateCallCenterApi(props.rowData.id, {
                            note: event.target.value
                        });
                        let updateCallSession = {...props.rowData};
                        updateCallSession.note = event.target.value;
                        dispatch(api.updateCallCancelById(props.rowData.id, updateCallSession));
                        dispatch({
                            type: CALL_DETAIL_DATA, payload: updateCallSession,
                        });
                    }
                    }

                    onKeyDown={(e) => {
                        console.log("e.key", e.key);
                        if (e.key === 'Enter') {
                            if (note) {
                                if (props.rowData && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                                    if (note && note.coords) {
                                        if (props.rowData.id) {
                                            updateCallCenterApi(props.rowData.id, {
                                                note: note.note,
                                            });
                                        }
                                        let updateCallSession = {...props.rowData};
                                        updateCallSession.note = note.note;
                                        if (props.rowData.id) {
                                            dispatch(api.updateCallCancelById(props.rowData.id, updateCallSession));
                                        }
                                        dispatch({
                                            type: CALL_DETAIL_DATA, payload: updateCallSession,
                                        });
                                        //dispatch(api.fetchCallsPending());
                                    }
                                }
                            } else {
                                let checkList = props.arrayOptions.filter(n => n.note === noteString);
                                if (checkList && checkList.length > 0) {
                                    if (props.rowData && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                                        if (note && note.coords) {
                                            if (props.rowData.id) {
                                                updateCallCenterApi(props.rowData.id, {
                                                    note: note.note,
                                                });
                                            }

                                            let updateCallSession = {...props.rowData};
                                            updateCallSession.note = note.note;
                                            if (props.rowData.id) {
                                                dispatch(api.updateCallCancelById(props.rowData.id, updateCallSession));
                                            }

                                            dispatch({
                                                type: CALL_DETAIL_DATA, payload: updateCallSession,
                                            });
                                        }
                                    }
                                    setNote(checkList[0]);
                                } else {
                                }

                            }
                            // eslint-disable-next-line no-unused-expressions
                            props.closeEditNote ? props.closeEditNote() : null;
                        }
                    }}
                />
            }
        />
    )
}
